//@ts-check

import React, { useState, useEffect, Fragment, useContext } from "react";
import { getArrOfNames } from '../../functions/fce-string';

import { IonLabel, IonCard, IonCardHeader,
         IonToast, IonButton, IonItem,
         IonSlides, IonSlide, IonAlert, IonChip,
         IonText, IonSegment, IonSegmentButton, IonCheckbox, IonIcon
} from '@ionic/react';

/* Theme variables */
import '../../theme/variables.css';


/* Icons */
import { optionsOutline, swapHorizontal } from "ionicons/icons";
import { RouteComponentProps } from "react-router";
import { useDataXMPP } from '../../functions/fetchXMPP';
import { prepareID } from "../../functions/fce-string";
import { TIMEOUT_TOAST_SAVED, TIMEOUT_TOAST_ERROR, sendXMPPmessage } from "../../App";
import { i18strings } from "../../functions/i18";
import { Help, Page, Wrapper, RegimesAssignment } from "../../functions/myReactComponents"
import { useHandleXmpp } from "../../functions/handleXmpp";
import { BwContext, SelectedDeviceContext, ctxBits } from "../../context/GlobalContext";
import { randomID } from "../../functions/fce-math";

interface UserDetailPageProps extends RouteComponentProps<{
  id: string;
  name: string;
}> {}

const PageHeatingRoomSet : React.FunctionComponent <UserDetailPageProps> = ({match}) => {
  const [showLoading, setShowLoading] = useState(true);
  const [showHelp, setShowHelp] = useState(false);
  const [showToastSaved, setShowToastSaved] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [isError, setIsError] = useState(false);
  const [unsaved, setUnsaved] = useState(0);

  const [modes] = useDataXMPP({ typefce: 'get', fce: '/listOfModes', param: '' }, '');
  const [settings] = useDataXMPP({ typefce: 'get', fce: '/roomSettings', param: prepareID(parseInt(match.params.id)) }, '');

  const [listRegimes, setListRegimes] = useState<{ id : number, name : string }[]>([]);
  const [firstDay, setFirstDay] = useState('01');
  const [anotherFirstDay, setAnotherFirstDay] = useState(-1);
  const [checkedWeek2, setCheckedWeek2] = useState(false);
  const [checkedWeek3, setCheckedWeek3] = useState(false);

  const defaultRegimes = (week: number) => [...Array(7)].map((v,i) => {
    return {
      id: week * 7 + i,
      value: -1,
    }
  });
  const dvc = useContext(SelectedDeviceContext);

  const [regimesOfRoom1, setRegimesOfRoom1] = useState(defaultRegimes(0));
  const [regimesOfRoom2, setRegimesOfRoom2] = useState(defaultRegimes(1));
  const [regimesOfRoom3, setRegimesOfRoom3] = useState(defaultRegimes(2));

  useHandleXmpp(modes.isLoading, modes.data, (xmpp)=>{
    let result : typeof listRegimes = [];
    try {
      if(xmpp.length >= 13) result = getArrOfNames(xmpp, 13).map((row, i) => {return {id: i, name: row}});
    }
    catch {
      result = [];
    }
    finally {
      setListRegimes(result);
    }
  });

  useHandleXmpp(settings.isLoading, settings.data, (xmpp) => {
    xmpp = xmpp.padEnd(42, "-1");
    let res = getArrOfNames(xmpp.slice(2), 2).map((x) => parseInt(x));
    for (let week = 0; week < 3; week++) {
      if (week && res[7 * week] === -1) break;
      [(x)=>{}, setCheckedWeek2, setCheckedWeek3][week](true);
      let clone = defaultRegimes(week);
      clone.forEach((val, i) => {
        val.value = res[i + 7 * week] % 32;
        if (res[i + 7 * week] >= 32) setAnotherFirstDay(7 * week + i);
      });
      [setRegimesOfRoom1, setRegimesOfRoom2, setRegimesOfRoom3][week](clone);
    }
    setFirstDay(xmpp.substr(0, 2));
    setShowLoading(false);
    setUnsaved(0);
  });

  useEffect(()=>{setTimeout(()=>setUnsaved(u => u | 2), 0)}, [regimesOfRoom1]);

  const handleOnSelectItem = (e, id:number) => {
    let week = id / 7 >> 0;
    const copy = [...[regimesOfRoom1, regimesOfRoom2, regimesOfRoom3][week]];
    copy[id % 7].value = parseInt(e.detail.value);
    [setRegimesOfRoom1, setRegimesOfRoom2, setRegimesOfRoom3][week](copy);
    setUnsaved(u => u | (u >> 1));
  }

  const handleOnCheckedWeek2 = (e) => {
    if(e.detail.checked) {
      setCheckedWeek2(true);
    }
    else {
      setFirstDay('01');
      setCheckedWeek2(false);
      setCheckedWeek3(false);
      setRegimesOfRoom2(defaultRegimes(1));
      setRegimesOfRoom3(defaultRegimes(2));
    }
    setUnsaved(u => u | (u >> 1));
  }

  const handleOnCheckedWeek3 = (e) => {
    if(e.detail.checked) {
      if(!checkedWeek2) {
        setCheckedWeek2(true);
      }
      setCheckedWeek3(true);
    }
    else {
      setFirstDay('01');
      setCheckedWeek3(false);
      setRegimesOfRoom3(defaultRegimes(2));
    }
    setUnsaved(u => u | (u >> 1));
  }

  const handleOnClickOK = async () => {
    //• ID místnosti (2 znaky)
    //• počáteční den (2 znaky, pouze 01,08,15)
    //• id režimů jednotlivých dnů (každý režim 2 znaky).  '-1' značí nepřiřazený režim a je doplněn do 21 dnů.

    let reg = '';
    [regimesOfRoom1, regimesOfRoom2, regimesOfRoom3].forEach(regime =>
      regime.forEach(r =>
        reg += prepareID(r.value)));

    {
      let ctrl = reg.split("-");
      ctrl.splice(0, 1);
      if(ctrl.some((v)=>{return v.length !== 1})){
        setIsError(true);
        return;
      }
    }

    reg = reg.padEnd(42, '-1');

    setUnsaved(2);
    await sendXMPPmessage(
      dvc,
      randomID(),
      'SET',
      '/saveAssignmentModes',
      prepareID(parseInt(match.params.id)) + firstDay + reg
    );

    setShowToastSaved(true);
  }

  return (
    <Wrapper loading={showLoading} help={() => setShowHelp(true)}>
      <Help isOpen={showHelp} onDidDismiss={e => setShowHelp(false)}>
        <p>{i18strings.help_heating_roomset}</p>
        <p>{i18strings.help_heating_roomset1}</p>
        <p>{i18strings.help_regime_assignment.split("{ic}").map((v, i, a) => <Fragment key={i}>
          {v}
          {i < a.length - 1 && <IonIcon icon={optionsOutline} style={{fontSize: "1.5em", marginBottom: "-5px"}} />}
        </Fragment>)}</p>
      </Help>

      <IonToast
        isOpen={showToastSaved}
        onDidDismiss={() => setShowToastSaved(false)}
        message={i18strings.action_settings_saved}
        duration={TIMEOUT_TOAST_SAVED}
      />

      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        header={i18strings.confirm}
        message={i18strings.confirm_delete_settings}
        buttons={[
          {
            text: i18strings.button_no,
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {}
          },
          {
            text: i18strings.button_yes,
            handler: () => {
              setRegimesOfRoom1(defaultRegimes(0));
              setRegimesOfRoom2(defaultRegimes(1));
              setRegimesOfRoom3(defaultRegimes(2));}
          }
        ]}
      />
      <BwContext.Consumer>
        {bc => (
      <Page title={i18strings.menu_heating_circuitsetting} icon={swapHorizontal} unsaved={!!(unsaved & 1)}>
        <IonItem color='primary'>
          {match.params.name}
        </IonItem>

        <IonButton expand="block" disabled={settings.isLoading || !(unsaved & 1)} onClick={handleOnClickOK}>{i18strings.action_save}</IonButton>

        <IonSlides pager={true} options={{
            initialSlide: 0,
            pager: true,
            stretch: 0,
            speed: 250,
            freemode: true,
            grabCursor: true,
            resistance: false
          }}>
          <IonSlide>
            <IonCard>
              <IonCardHeader>
                <IonChip><IonLabel> {i18strings.menu_heating_1st_week_regimes}</IonLabel></IonChip>
              </IonCardHeader>
              <RegimesAssignment
                regimeAssignments={regimesOfRoom1}
                defaultRegime={-1}
                loc="/heating/regimelist"
                handleSelect={handleOnSelectItem}
                possibleRegimes={listRegimes}
                firstDayOfCycle={anotherFirstDay}
              />
            </IonCard>
          </IonSlide>

          <IonSlide>
            <IonCard disabled={!!(bc.bits & ctxBits.cooling)}>
              <IonCardHeader>
                <IonChip>
                  <IonCheckbox checked={checkedWeek2} onIonChange={handleOnCheckedWeek2} />&nbsp;
                  <IonLabel>{i18strings.menu_heating_2nd_week_regimes}</IonLabel>
                </IonChip>
              </IonCardHeader>
              {checkedWeek2 ? <RegimesAssignment
                regimeAssignments={regimesOfRoom2}
                defaultRegime={-1}
                loc="/heating/regimelist"
                handleSelect={handleOnSelectItem}
                possibleRegimes={listRegimes}
                firstDayOfCycle={anotherFirstDay - 7}
              /> : ""}
              {bc.bits & ctxBits.cooling ? <IonLabel>{i18strings.slides_cooling}</IonLabel> : ""}
            </IonCard>
          </IonSlide>

          <IonSlide>
            <IonCard disabled={!!(bc.bits & ctxBits.cooling)}>
              <IonCardHeader>
                <IonChip>
                  <IonCheckbox checked={checkedWeek3} onIonChange={handleOnCheckedWeek3} />&nbsp;
                  <IonLabel>{i18strings.menu_heating_3rd_week_regimes}</IonLabel>
                </IonChip>
              </IonCardHeader>
              {checkedWeek3 ? <RegimesAssignment
                regimeAssignments={regimesOfRoom3}
                defaultRegime={-1}
                loc="/heating/regimelist"
                handleSelect={handleOnSelectItem}
                possibleRegimes={listRegimes}
                firstDayOfCycle={anotherFirstDay - 14}
              /> : ""}
              {bc.bits & ctxBits.cooling ? <IonLabel>{i18strings.slides_cooling}</IonLabel> : ""}
            </IonCard>
          </IonSlide>
        </IonSlides>

        <IonText color='primary' class='ion-align-items-center'>{i18strings.menu_heating_week_start}: </IonText>
        <IonSegment value={firstDay} onIonChange={(e)=>setFirstDay(e.detail.value!)}>
          <IonSegmentButton value='01'>
            <IonLabel>{i18strings.menu_heating_1st_week}</IonLabel>
          </IonSegmentButton>

          {bc.bits & ctxBits.cooling ? '' :
            <IonSegmentButton disabled={!checkedWeek2} value='08'>
              <IonLabel>{i18strings.menu_heating_2nd_week}</IonLabel>
            </IonSegmentButton>}

          {bc.bits & ctxBits.cooling ? '' :
            <IonSegmentButton  disabled={!checkedWeek3} value='15'>
              <IonLabel>{i18strings.menu_heating_3rd_week}</IonLabel>
            </IonSegmentButton>}
        </IonSegment>

        <IonButton expand="block" disabled={settings.isLoading || !(unsaved & 1)} onClick={handleOnClickOK}>{i18strings.action_save}</IonButton>
        <IonLabel>&nbsp;</IonLabel>
        <IonButton expand="block" disabled={settings.isLoading} onClick={()=>setShowAlert(true)}>{i18strings.button_delete_settings}</IonButton>
      </Page>)}
      </BwContext.Consumer>

      {modes.err}
      {settings.err}
      <IonToast
        isOpen={isError}
        onDidDismiss={() => setIsError(false)}
        message={i18strings.menu_heating_previousregime_warning}
        position="bottom"
        duration={TIMEOUT_TOAST_ERROR}
        buttons={[
          {
            text: 'OK',
            role: 'cancel'
          }
        ]}
      />
    </Wrapper>
  )
}
export default PageHeatingRoomSet;