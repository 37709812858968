import { i18strings } from "./i18";

const sampleRegimes = [
  {
    name: i18strings.heating_samples_antifreeze,
    reg: "00:00005"
  }, {
    name: "18°C",
    reg: "00:00018"
  }, {
    name: "20°C",
    reg: "00:00020"
  }, {
    name: i18strings.heating_samples_comfort,
    reg: "00:00022"
  }, {
    name: i18strings.heating_samples_max_floors,
    reg: "00:00026"
  }, {
    name: i18strings.heating_samples_work,
    reg: "00:00020" + 
         "05:00022" +
         "08:00020" +
         "14:00022" +
         "22:00020"
  }, {
    name: i18strings.heating_samples_wend,
    reg: "00:00020" + 
         "05:00022" +
         "12:00021" +
         "12:05022" +
         "18:00021" +
         "18:05022" +
         "22:00021"
  }, { // stejné jako 18°C
    name: i18strings.heating_samples_vestibule,
    reg: "00:00018"
  }, {
    name: i18strings.heating_samples_bath_floor,
    reg: "00:00020" +
         "05:00024" +
         "08:00020" +
         "18:00024" +
         "21:00020"
  }, {
    name: i18strings.heating_samples_bath_ladder,
    reg: "00:00020" +
         "07:00024" +
         "09:00020" +
         "19:00024" +
         "22:00020"
  }
]

export default sampleRegimes;