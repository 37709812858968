//@ts-check

import React, { useState } from "react";
import { getArrOfNames, prepareID } from '../../functions/fce-string';

import { IonLabel, IonCard, 
         IonCardContent, IonRange, IonIcon, 
         IonToast, IonButton, IonList, IonItem, IonSelect, IonSelectOption, IonText
} from '@ionic/react';

/* Theme variables */
import '../../theme/variables.css';


/* Icons */
import { handRight } from "ionicons/icons";
import { useDataXMPP } from '../../functions/fetchXMPP';

import roller0 from "../../img/roller0.svg";
import roller10 from "../../img/roller10.svg";
import { TIMEOUT_TOAST_SAVED } from "../../App";
import { i18strings } from "../../functions/i18";
import { Help, Page, Wrapper } from "../../functions/myReactComponents";
import { useHandleXmpp } from "../../functions/handleXmpp";

const PageRollerManual = (props : any) => {
  const [showLoading, setShowLoading] = useState(true);
  const [showHelp, setShowHelp] = useState(false);
  const [showToastSaved, setShowToastSaved] = useState(false);

  //Načtení názvu mezipolohy
  const [inter, doFetchXmpp] = useDataXMPP(
    {
      typefce: 'get',
      fce: '/rollerShutterIntermediate',
      param: ''
    },
    '');

  const [intermediate, setIntermediate] = useState('');
  const [manualPosition, setManualPosition] = useState(0);
  const [manualRotation, setManualRotation] = useState(0);
  const [rotationDisable, setRotationDisable] = useState(true);
    
  useHandleXmpp(inter.isLoading, inter.data, (resultXmpp) => {
      setIntermediate(resultXmpp.trim());
  });
  
  //----Načtení názvů rolet
  const [{ data: dataXmpp2, isLoading: isLoadingXmpp2 }] = useDataXMPP(
    {
      typefce: 'get',
      fce: '/listOfRollerShutters',
      param: ''
    },
    '');

  const [listRooms, setListRooms] = useState<{ id : number, name : string, checked : boolean }[]>([]);

  useHandleXmpp(isLoadingXmpp2, dataXmpp2, (resultXmpp) => {
    let result : typeof listRooms = [];
    
    try {
      result = getArrOfNames(resultXmpp, 13).map((row, i) => {
        return { id: i, name: row, checked: false };
      });
    }
    catch(e) {
      console.error(e);
      result = [];
    }
    finally {
      setListRooms(result);
      setShowLoading(false);
    }
  });
  
  const ListRooms = () => {
    return (
      <>
        {listRooms.map((item, index) => (
          <IonItem key={item.id}>
            <IonLabel>{item.name}</IonLabel>
            <IonButton disabled={inter.err !== null} onClick={(e)=>handleOnClickRoom(e, item.id)}>{i18strings.menu_roller_manualsend}</IonButton>
          </IonItem>
        ))}
      </>
    );
  }

  const handleOnSelectItem = (e) => {
    let p = parseInt(e.detail.value);
    if (!p) setManualRotation(0);
    setRotationDisable(!p);
    setManualPosition(p);
  }

  const handleOnClickRoom = (e, id: number) => {
    let p = prepareID(id)
     + manualPosition.toString()
     + prepareID(manualRotation);
        
    const saveXmppObject = {
      typefce: 'SET',
      fce: '/saveManualChange',
      param: p
    }

    doFetchXmpp(saveXmppObject);

    setShowToastSaved(true);
  }

  return (
<Wrapper loading={showLoading} help={() => setShowHelp(true)}>
  <Help isOpen={showHelp} onDidDismiss={e => setShowHelp(false)}>
    <p>{i18strings.help_roller_manual}</p>
  </Help>

  <IonToast
    isOpen={showToastSaved}
    onDidDismiss={() => setShowToastSaved(false)}
    message={i18strings.action_settings_saved}
    duration={TIMEOUT_TOAST_SAVED}
  />

  <Page title={i18strings.menu_roller_manualchange} icon={handRight}>
    <IonCard>
      <IonCardContent>
        <IonList>
          <IonItem>
            <IonLabel>{i18strings.menu_roller_position}:</IonLabel>
            <IonSelect value={manualPosition.toString()} interface="popover" onIonChange={(e)=>handleOnSelectItem(e)} >
              <IonSelectOption value="0">{i18strings.menu_roller_position0}</IonSelectOption>
              <IonSelectOption value="1">{i18strings.menu_roller_position1}</IonSelectOption>
              <IonSelectOption value="2">{i18strings.menu_roller_position2}</IonSelectOption>
              <IonSelectOption value="3">{intermediate}</IonSelectOption>
            </IonSelect>
          </IonItem>
          <IonItem lines="none">
            <IonLabel>{i18strings.menu_roller_rotation} 0-10:</IonLabel>
            <IonText>{manualRotation}&nbsp;</IonText>
          </IonItem>
          <IonItem>
            <IonRange min={0} max={10} step={1} value={manualRotation} disabled={rotationDisable}
              style={{padding:"0px"}}
              onIonChange={(e) => setManualRotation(parseInt(e.detail.value.toString()))} >
              <IonIcon slot="start" icon={roller0}/>
              <IonIcon slot="end" icon={roller10}/>
            </IonRange>
          </IonItem>
          <IonItem lines='none'>
            <IonLabel>{i18strings.menu_roller_manualselect}:</IonLabel>
          </IonItem>
          <IonItem style={{"marginBottom": "16px"}}>
            <IonLabel>{i18strings.roller_manual_all}</IonLabel>
            <IonButton disabled={inter.err !== null || (listRooms.length === 0)} onClick={(e)=>handleOnClickRoom(e, -1)}>{i18strings.menu_roller_manualsend}</IonButton>
          </IonItem>
          <ListRooms/>
        </IonList>
      </IonCardContent>
    </IonCard>
  </Page>

  {inter.err}
</Wrapper>
  )
}
export default PageRollerManual;  
