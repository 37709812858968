//@ts-check

import React, { useState, useLayoutEffect } from "react";
import { useHistory } from 'react-router';
import { RefresherEventDetail } from '@ionic/core';
import { parseBoolean } from '../../functions/fce-math';

import { IonList, IonItem, IonLabel, 
         IonNote, IonIcon, IonGrid, IonRow, IonCol, 
         IonText, IonItemSliding, IonItemOptions, IonItemOption
} from '@ionic/react';

/* Theme variables */
import '../../theme/variables.css';


/* Icons */
import { informationCircle, handRight } from "ionicons/icons";
import central from "../../img/central.svg";
import roller from "../../img/roller.svg";
import shutter from "../../img/shutter.svg";
import shutterdisable from "../../img/shutter-disable.svg";


import { useDataXMPP } from '../../functions/fetchXMPP';
import { useFetchRoomsXMPP } from "../../functions/fetchRoomsXMPP";
import { i18strings } from "../../functions/i18";
import { MyIonRefresher, Help, Page, Wrapper, ErrToast } from "../../functions/myReactComponents";


type RoomData = { 
  id: number,
  use: number,
  name: string,
  actualPosition: number, //uvedeno v %
  setPosition: number,
  centralActive: boolean,
  manualActive: boolean,
  error: number,
  validity: number,
  setRotation: number,
  manualPosition: number, //uvedeno v %
  manualRotation: number,
  actualRotation: number
}


const PageRollerInfoRoom = (props : any) => {  
  const h = useHistory();
  const [showHelp, setShowHelp] = useState(false);

  //Počet místností
  const defaultXmppObject = {
    typefce: 'get',
    fce: '/numOfRollerShutters',
    param: ''
  }
  const [nRollers] = useDataXMPP(defaultXmppObject, '');  

  useLayoutEffect(function fce() {
    // 👍 We're not breaking the first rule anymore
    if (!nRollers.isLoading && nRollers.data !=='') {
      doFetchXmppListRooms(defaultXmppObjectListRooms);
    }
  }, [nRollers.data, nRollers.isLoading]);
 

  //List místností
  const defaultXmppObjectListRooms = {
    typefce: 'get',
    fce: '/moreRollerShutter',
    param: nRollers.data
  }
  const [roomsXmpp, doFetchXmppListRooms] = useFetchRoomsXMPP(
    defaultXmppObjectListRooms,
    [], 33
  );  
  const doXmppRefresh = (e : CustomEvent<RefresherEventDetail>) => {
    doFetchXmppListRooms(
      defaultXmppObjectListRooms,
    );
    e.detail.complete();
  }
  
  const RollerIcon = (props : any) => {
    var icon = '';
    switch (props.typeroller) {
      case 0: icon = shutterdisable;
        break;
      case 1: icon = roller;
        break;
      case 2: icon = shutter;
        break;
    }
    return (
      <IonIcon icon={icon}></IonIcon>
    )
  }

  const RollerPositionProc = (props : any) => {
    let textRotate = '';
    if(props.typeroller === 2){
      textRotate = '/' + (props.rotate);
    }

    let textManual = '';
    if(props.isManual) {
      textManual += ' (' + props.manualPosition +'%';
      if (props.typeroller === 2) {
        textManual += '/' + props.manualRotation;
      }
      textManual += ')';
    }

    return (
      <>
        {props.id === 0 ? <IonText color={props.color}><small>{i18strings.menu_roller_actualposition}:</small><br></br></IonText> : ''}
        <IonText color={props.color}><small>{props.position}%{textRotate}</small></IonText><IonText color={props.colorManual}><small>{textManual}</small></IonText>
      </>
    )
  }

  const RollerPositionText = (props : any) => {
    return (
<>
  {props.id === 0 ? <IonText color={props.color}><small>{i18strings.menu_roller_settingposition}:</small><br></br></IonText> : ''}
  <IonText color={props.color}>
    <small>
      {[i18strings.menu_roller_position0, i18strings.menu_roller_position1, i18strings.menu_roller_position2,
        i18strings.menu_roller_position3][props.position]}{(props.typeroller === 2) ? '/' + (props.rotate) : ''}
    </small>
  </IonText>
</>
    )
  }

  const RenderListRooms = () => {
    if (roomsXmpp.isLoadingXmppListRooms || roomsXmpp.isErrorXmppListRooms) return <></>;

    var data : RoomData[] = [];
    let i = 0;
    try {
      roomsXmpp.dataXmppListRooms.forEach(row => {
        const room = { 
          id: i,
          use: parseInt(row.substr(0,1)),
          name: row.substr(1,13),
          actualPosition: parseInt(row.substr(14,3), 10) || 0, //uvedeno v %
          setPosition: parseInt(row.substr(17,1)),
          centralActive: parseBoolean(row.substr(18,1)),
          manualActive: parseBoolean(row.substr(19,1)),
          error: parseInt(row.substr(20,1), 10) || 0,
          validity: parseInt(row.substr(21,3)),
          setRotation: parseInt(row.substr(24,2)),
          manualPosition: parseInt(row.substr(26,3)), //uvedeno v %
          manualRotation: parseInt(row.substr(29,2)),
          actualRotation: parseInt(row.substr(31,2))
        };
      
        data.push(room);
        i ++;
      });
    }
    catch {
      data = [];
    }

    return (
      <>
        { data.map((item, index) => (
          <IonItemSliding key={item.id}>
          <IonItem key={item.id} href="" onClick={(e) => {e.preventDefault(); h.push('/roller/roomlist/room/'+item.id+'/'+item.name)}}>
            <IonGrid>
              <IonRow>
                <IonCol size='8'><IonLabel color='primary'>{item.name}</IonLabel></IonCol>
                <IonCol size='4'>
                  <IonNote><RollerIcon typeroller={item.use}></RollerIcon></IonNote>
                  <IonNote>{item.manualActive ? <IonIcon color='warning' icon={handRight}/> : ''}</IonNote >
                  <IonNote>{item.centralActive ? <IonIcon color='dark' icon={central}/> : ''}</IonNote >
                  <IonNote color='danger'>{item.validity > 0 ? 'v'+item.validity : ''}</IonNote>
                  <IonNote color='danger'>{item.error > 0 ? 'e'+item.error : ''}</IonNote>
                </IonCol>
              </IonRow>

              <IonRow >
                <IonCol size='6'>
                  <RollerPositionProc 
                    id={item.id}
                    color='dark' 
                    colorManual='warning'
                    typeroller={item.use}
                    position={item.actualPosition}
                    rotate={item.actualRotation}
                    isManual={item.manualActive}
                    manualPosition={item.manualPosition}
                    manualRotation={item.manualRotation}
                  ></RollerPositionProc>

                  </IonCol>
                  <IonCol size='6'>
                    <RollerPositionText 
                      id={item.id}
                      color='dark' 
                      typeroller={item.use}
                      position={item.setPosition}
                      rotate={item.setRotation}
                    ></RollerPositionText>
                  </IonCol>
              </IonRow>

            </IonGrid>
          </IonItem>
          <IonItemOptions side="end">
            <IonItemOption href="" onClick={(e) => {e.preventDefault(); h.push('/roller/manual')}}><IonIcon icon={handRight}/></IonItemOption>
        </IonItemOptions>
        </IonItemSliding>
        ))}
      </>
    );
  }

  return (
    <Wrapper loading={roomsXmpp.isLoadingXmppListRooms} help={() => setShowHelp(true)}>
      <MyIonRefresher onIonRefresh={doXmppRefresh}></MyIonRefresher>
      
      <Help isOpen={showHelp} onDidDismiss={e => setShowHelp(false)}>
          <p>{i18strings.help_roller_inforoom}</p>
          <p>{i18strings.help_roller_inforoom1}</p>
          <p>{i18strings.help_roller_inforoom2}</p>
          <p>{i18strings.help_roller_inforoom3}</p>
          <p>{i18strings.help_roller_inforoom4}</p>
          <p>{i18strings.help_roller_inforoom5}</p>
          <ul>
            <li><IonIcon icon={roller}/> - {i18strings.help_roller_inforoom6}</li>
            <li><IonIcon icon={shutter}/> - {i18strings.help_roller_inforoom7}</li>
            <li><IonIcon icon={shutterdisable}/> - {i18strings.help_roller_inforoom8}</li>
            <li><IonIcon icon={central}/> - {i18strings.help_roller_inforoom9}</li>
            <li><IonIcon icon={handRight}/> - {i18strings.help_roller_inforoom10}</li>
            <li><IonNote color='danger'>&nbsp;v_</IonNote> - {i18strings.help_roller_inforoom11}</li>
            <li><IonNote color='danger'>&nbsp;e1</IonNote> - {i18strings.help_roller_inforoom12}</li>
            <li><IonNote color='danger'>&nbsp;e2</IonNote> - {i18strings.help_roller_inforoom13}</li>
            <li><IonNote color='danger'>&nbsp;e3</IonNote> - {i18strings.help_roller_inforoom14}</li>
            <li><IonNote color='danger'>&nbsp;e4</IonNote> - {i18strings.help_roller_inforoom15}</li>
          </ul>
      </Help>

      <Page title={i18strings.menu_roller_inforoom} icon={informationCircle}>
        <IonList>
          <RenderListRooms />
        </IonList>
      </Page>

      {nRollers.err}
      <ErrToast isOpen={roomsXmpp.isErrorXmppListRooms} message={roomsXmpp.errMsgXmppListRooms} />
    </Wrapper>
  )
};
export default PageRollerInfoRoom;
