//@ts-check

import * as CryptoJs from "crypto-js";
import { DEBUG } from '../App';
import { useEffect } from "react";

export function TimezonelessNow(offsetDays=0) {
  let now = new Date();
  now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
  now.setDate(now.getDate() + offsetDays)
  return now.toISOString()
}

export function parseTag(text: string, tag: string) : string {
    var a : string = '[' + tag + ']';
    var b : string = '[/' + tag + ']';

    var pos1 = text.lastIndexOf(a) + a.length;

    if(pos1 < a.length) return "";
    else
    {
      var pos2 = text.lastIndexOf(b);
      if(pos2 > a.length) return text.substring(pos1, pos2);
      else return "";
    }
}

export function log(text:string, err:boolean) {
  if(DEBUG) {
    (err ? console.error : console.log)(err ? '❌' : "", new Date().toLocaleString() + ": " + text);
  }
}

export function HC64dateTimeFormat2ISO(str: string) {
  //Formát od řídící jednotky: '2012-01-2008:30'
  //Formát ISO: '2014-02-11T11:30:30'
  if (str.length === 15)
    return str.substring(0,10) + "T" + str.substring(10)
  else
    return '2000-01-01T00:00:00';
}

export function ISOdateTimeFormat2HC64(s: string) {
  //Formát ISO od IonDatetime: 2019-10-23T12:33:52.775+02:00
  //Formát HC64: '2019-10-2311:55'
  try {
    // : je potreba nahradit kvuli komunikaci
    return s.substring(0,10) + s.substring(11,16).replace(":", "%3A");
  } catch {
    return '2000-01-0100%3A00';
  }
}

//Doplni nuly pred cislo pro ukladani tří-místných integer teplot
export function prepareSaveTemp(temp: number) : string {
    if (temp > 99) temp = 99;
    return (temp < 0 ? '-' : '0') + Math.abs(temp).toString().padStart(2, "0");
}

//Doplni ID na dve mista 
export const prepareID = (id) => id.toString().padStart(2, "0");

export function getArrOfNames(s: string, lengths: number) {
  let res : string[] = [];
  for (let i = 0; i < s.length; i += lengths) {
    res.push(s.slice(i, i + lengths));
  }
  return res;
}

export const addMinutes = (t:string, n=1) => {
  if (!t.match(/^[0-9]{2}:[0-9]{2}$/)) return "00:00";
  let [h, m] = t.split(":").map(v => parseInt(v));
  m += n;
  if (m >= 60) {
    m -= 60;
    if (++h === 24) h = 0;
  } else if (m < 0) {
    if (!h--) h = 23;
    m += 60;
  }
  return [h, m].map(v => v.toString().padStart(2, "0")).join(":");
}

export function compareTime(timeA: string, timeB: string) {
  /*
  TimeA - new/next time
  TimeB - previous time
  */
  if (timeA === "00:00" || timeA > timeB) return timeA;
  return addMinutes(timeB);
}

const diacritics = {
  a: 'ÀÁÂÃÄÅàáâãäåĀāąĄ',
  c: 'ÇçćĆčČ',
  d: 'đĐďĎ',
  e: 'ÈÉÊËèéêëěĚĒēęĘ',
  i: 'ÌÍÎÏìíîïĪī',
  l: 'łŁ',
  n: 'ÑñňŇńŃ',
  o: 'ÒÓÔÕÕÖØòóôõöøŌō',
  r: 'řŘ',
  s: 'ŠšśŚ',
  t: 'ťŤ',
  u: 'ÙÚÛÜùúûüůŮŪū',
  y: 'ŸÿýÝ',
  z: 'ŽžżŻźŹ',
  ' ': '=*-_:;§€/|\\[]+.&#@{}()><*÷×¤ß$¨%,\'`~'
}

export function replaceDiacritic(text: string) {
  return text.split('').map(l => Object.keys(diacritics).find(k => diacritics[k].includes(l)) || l).join(''); 
}

export function parseNaN2questionMarks(n: number, precission = 1) {
  if(isNaN(n)) return '??.?';
  else return n.toFixed(precission).padStart(4, String.fromCharCode(160));
}

export function toBase64(s: string) {
  return CryptoJs.enc.Base64.stringify(CryptoJs.enc.Utf8.parse(s));
}

export function encrypt(s:string) {
  return CryptoJs.enc.Base64.stringify(CryptoJs.enc.Utf8.parse(s));
}

export function decrypt(s:string) : string {
  return CryptoJs.enc.Utf8.stringify(CryptoJs.enc.Base64.parse(s));
}

export const useDebug = (variable) => {
  useEffect(()=>console.log(variable), [variable]);
}
