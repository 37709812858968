//@ts-check

import React, { useState } from "react";
import { RefresherEventDetail } from '@ionic/core';
import { getArrOfNames } from '../../functions/fce-string';

import { IonLabel, IonList, IonItem } from '@ionic/react';

/* Theme variables */
import '../../theme/variables.css';


/* Icons */
import { options } from "ionicons/icons";

import { useDataXMPP } from '../../functions/fetchXMPP';
import { i18strings } from "../../functions/i18";
import { MyIonRefresher, Page, Wrapper } from "../../functions/myReactComponents";
import { useHandleXmpp } from "../../functions/handleXmpp";

const PageHeatingRegimeList = (props : any) => {
  const [showLoading, setShowLoading] = useState(true);

  const defaultXmppObject = {
    typefce: 'get',
    fce: '/listOfModes',
    param: ''
  }
  const [modes, doFetchXmpp] = useDataXMPP(
    defaultXmppObject,
    '',
    props.refresh,
  );

  const [listRegimes, setListRegimes] = useState<{id: number, name: string}[]>([]);

  useHandleXmpp(modes.isLoading, modes.data, (resultXmpp) => {
    let result: {id: number, name: string}[] = [];

    try {
      if(resultXmpp.length >= 13) {
        result = getArrOfNames(resultXmpp, 13)?.map((row, i) => {return { 
          id: i,
          name: row
        }}) ?? [];
        setShowLoading(false);
      }
    } catch {
      result = [];
    } finally {
      setListRegimes(result);
    }
  });
  
  const doXmppRefresh = (e : CustomEvent<RefresherEventDetail>) => {
    doFetchXmpp(defaultXmppObject);
    e.detail.complete();
  }

  const ListRegimes = () => {
    return (
      <>
        {listRegimes.map((item, index) => (
          <IonItem key={item.id} routerLink={'/heating/regimelist/regime/' + index.toString() +'/' + item.name}>
            <IonLabel>{item.name}</IonLabel>
          </IonItem>
        ))}
      </>
    );
  }

  return (
    <Wrapper loading={showLoading}>
      <MyIonRefresher onIonRefresh={doXmppRefresh}></MyIonRefresher>

      <Page title={i18strings.menu_heating_programsetting} icon={options} subt={i18strings.menu_heating_regimelist_subtitle}>
        <IonList>
          <ListRegimes/>
        </IonList>
      </Page>

      {modes.err}
    </Wrapper>
  )
}
export default PageHeatingRegimeList;  
