//@ts-check

import React, { useState } from "react";
import { RefresherEventDetail } from '@ionic/core';

import { IonIcon, IonText,
         IonToast, IonButton, IonList, IonItem,
         IonRange, IonLabel
} from '@ionic/react';

/* Theme variables */
import '../../theme/variables.css';

/* Icons */
import { thermometer } from "ionicons/icons";

import { useDataXMPP } from '../../functions/fetchXMPP';
import { RouteComponentProps } from "react-router";
import { prepareID } from "../../functions/fce-string";
import { TIMEOUT_TOAST_SAVED } from "../../App";

import { i18strings } from "../../functions/i18";
import { MyIonRefresher, Help, Page, Wrapper } from "../../functions/myReactComponents";
import { useHandleXmpp } from "../../functions/handleXmpp";

interface UserDetailPageProps extends RouteComponentProps<{
  id: string;
  name: string;
}> {}

const PageHeatingFloorSet : React.FunctionComponent <UserDetailPageProps> = ({match}) => {
  const [showLoading, setShowLoading] = useState(true);
  const [unsaved, setUnsaved] = useState(0);

  const defaultXmppObject = {
    typefce: 'get',
    fce: '/loadMinFloor',
    param: prepareID(parseInt(match.params.id))
  }
  const [minFloorXmpp, doFetchXmpp] = useDataXMPP(defaultXmppObject, '');

  const [minFloor, setMinFloor] = useState(NaN);
  const [showHelp, setShowHelp] = useState(false);
  const [showToastSaved, setShowToastSaved] = useState(false);

  useHandleXmpp(minFloorXmpp.isLoading, minFloorXmpp.data, (resultXmpp)=> {
    let result = 0;
    try {
      if(resultXmpp.length === 2) {
        result = parseFloat(resultXmpp.substr(0,2));
        if(isNaN(result)) result = 0;
      }
    }
    catch {
      result = 0;
    }
    finally {
      setMinFloor(result);
      setShowLoading(false);
      setUnsaved(0);
    }
  });

  const doXmppRefresh = (e : CustomEvent<RefresherEventDetail>) => {
    doFetchXmpp(defaultXmppObject);
    e.detail.complete();
  }

  const handleOnChange = (e) => {
    if(isNaN(minFloor)) return;
    setMinFloor(e.detail.value);
    setUnsaved(u => 2 | (u >> 1));
  }

  const handleOnClickOK = () => {
    //write: '0006'
    var id = prepareID(parseInt(match.params.id));
    var temp = prepareID(minFloor);

    doFetchXmpp({typefce: 'SET', fce: '/saveMinFloor', param: id + temp});

    setShowToastSaved(true);
    setUnsaved(2);
  }

  return (
    <Wrapper loading={showLoading} help={() => setShowHelp(true)}>
      <MyIonRefresher onIonRefresh={doXmppRefresh} />

      <Help isOpen={showHelp} onDidDismiss={e => setShowHelp(false)}>
        <p>{i18strings.help_heating_floorset}</p>
      </Help>

      <IonToast
        isOpen={showToastSaved}
        onDidDismiss={() => setShowToastSaved(false)}
        message={i18strings.action_settings_saved}
        duration={TIMEOUT_TOAST_SAVED}
      />

      <Page title={i18strings.menu_heating_minfloortemp} icon={thermometer} unsaved={!!(unsaved & 1)}>
        <IonList>
          <IonItem color='primary'>{match.params.name}</IonItem>
          <IonItem lines="none">
            <IonLabel>{i18strings.menu_heating_minfloortemp}:</IonLabel><IonText>{minFloor}°C</IonText>
          </IonItem>
          <IonItem>
            <IonIcon size="small" slot="start" icon={thermometer}/>
            <IonRange min={0} max={40} step={1} pin={true} value={minFloor} onIonChange={handleOnChange}></IonRange>
            <IonIcon slot="end" icon={thermometer}/>
          </IonItem>
        </IonList>
        <IonButton disabled={minFloorXmpp.err !== null || minFloorXmpp.isLoading || !(unsaved & 1)} expand="block" onClick={handleOnClickOK}>{i18strings.action_save}</IonButton>
      </Page>

      {minFloorXmpp.err}
    </Wrapper>
  )
}
export default PageHeatingFloorSet;
