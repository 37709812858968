import React, { FC, Fragment, useContext, useState } from "react";
import { getArrOfNames } from '../../functions/fce-string';

import { IonCard, IonToast, IonButton, IonAlert, IonIcon } from '@ionic/react';

/* Theme variables */
import '../../theme/variables.css';


/* Icons */
import { optionsOutline, swapHorizontal } from "ionicons/icons";
import { useDataXMPP } from '../../functions/fetchXMPP';
import { prepareID } from "../../functions/fce-string";
import { TIMEOUT_TOAST_SAVED, TIMEOUT_TOAST_ERROR, sendXMPPmessage } from "../../App";
import { i18strings } from "../../functions/i18";
import { Help, Page, Wrapper, RegimesAssignment } from "../../functions/myReactComponents";
import { useHandleXmpp } from "../../functions/handleXmpp";
import { useMultiRoomRollerSelection } from "../../components/RoomSelection";
import { SelectedDeviceContext } from "../../context/GlobalContext";
import { randomID } from "../../functions/fce-math";

const defaultRegimes = () => [...Array(7)].map((v, i) => {
  return { value: 99, id: i };
});

const MassRollerAssign : FC = () => {
  const [showHelp, setShowHelp] = useState(false);
  const [showToastSaved, setShowToastSaved] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertRegime, setShowAlertRegime] = useState(false);
  const [isAlertRegime, setIsAlertRegime] = useState(false);
  const [isError, setIsError] = useState(false);

  const [progList] = useDataXMPP({typefce: 'get', fce: '/listOfPrograms', param: ''}, '');

  const [listRegimes, setListRegimes] = useState<{ id : number, name : string }[]>([]);
  const [regimesOfRoom, setRegimesOfRoom] = useState(defaultRegimes());
  const dvc = useContext(SelectedDeviceContext);

  const multiroom = useMultiRoomRollerSelection();

  useHandleXmpp(progList.isLoading, progList.data, (resultXmpp) => {
    let result : typeof listRegimes = [];

    try {
      result = getArrOfNames(resultXmpp, 13).map((row, i) => {
        return { id: i, name: row};
      });
    }
    catch(e) {
      console.error(e);
      result = [];
    }
    finally {
      setListRegimes(result);
    }
  });

  const handleOnSelectItem = (e, index) => {
    const copy = [...regimesOfRoom];
    copy[index].value = parseInt(e.detail.value);
    setRegimesOfRoom(copy);
  }

  const handleOnClickOK = async () => {
    //• ID místnosti (2 znaky)
    //• id režimů jednotlivých dnů (každý režim 2 znaky). Posílá se buď jedno ID nebo sedm ID (celý týden)!

    let isErr = false;
    let reg = '';
    for(let i = 0; i < regimesOfRoom.length; i++) {
      let regime = regimesOfRoom[i].value;

      if (regime === 99) {
        if(i > 1) {
          // incomplete week
          isErr = true;
          setIsAlertRegime(true);
          setShowAlertRegime(true);
        }
        break;
      }
      reg += prepareID(regime);
    };

    if(isErr || isAlertRegime) return;

    for (let room of multiroom.selected()) {
      await sendXMPPmessage(
        dvc,
        randomID(),
        'SET',
        '/saveRollerShutterSettings',
        prepareID(room) + reg
      );
    }

    multiroom.setState(0);
    setShowToastSaved(true);
  }

  return (
    <Wrapper loading={progList.isLoading} help={() => setShowHelp(true)}>
      <Help isOpen={showHelp} onDidDismiss={e => setShowHelp(false)}>
        <p>{i18strings.help_roller_room}</p>
        <p>{i18strings.help_regime_assignment.split("{ic}").map((v, i, a) => <Fragment key={i}>
          {v}
          {i < a.length - 1 && <IonIcon icon={optionsOutline} style={{fontSize: "1.5em", marginBottom: "-5px"}} />}
        </Fragment>)}</p>
      </Help>

      <IonToast
        isOpen={showToastSaved}
        onDidDismiss={() => setShowToastSaved(false)}
        message={i18strings.action_settings_saved}
        duration={TIMEOUT_TOAST_SAVED}
      />

      <IonAlert
        isOpen = {showAlert}
        onDidDismiss = {() => setShowAlert(false)}
        header = {i18strings.confirm}
        message = {i18strings.confirm_delete_settings}
        buttons = {[
          {
            text: i18strings.button_no,
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {}
          },
          {
            text: i18strings.button_yes,
            handler: () => {setRegimesOfRoom(defaultRegimes()); }
          }
        ]}
      />
      <IonAlert
        isOpen = {showAlertRegime}
        onDidDismiss = {() => setShowAlertRegime(false)}
        header = {i18strings.confirm}
        message = {i18strings.menu_roller_regimewarning1}
        buttons = {[{text: 'OK', handler: () => setIsAlertRegime(false)}]}
      />

      <Page title={i18strings.menu_roller_masscircuitsetting} icon={swapHorizontal}>
        <IonButton expand="block" onClick={()=>setShowAlert(true)}>{i18strings.button_delete_settings}</IonButton>

        <IonCard>
          <RegimesAssignment
            regimeAssignments={regimesOfRoom}
            defaultRegime={99}
            loc="/roller/regimelist"
            handleSelect={handleOnSelectItem}
            possibleRegimes={listRegimes}
          />
        </IonCard>

        <IonButton expand="block" disabled={!multiroom.state} onClick={handleOnClickOK}>{i18strings.action_save}</IonButton>
        {multiroom.node}
        <IonButton expand="block" disabled={!multiroom.state} onClick={handleOnClickOK}>{i18strings.action_save}</IonButton>
      </Page>

      {progList.err}
      <IonToast
        isOpen={isError}
        onDidDismiss={() => setIsError(false)}
        message = {i18strings.menu_roller_regimewarning2}
        position = "bottom"
        duration = {TIMEOUT_TOAST_ERROR}
        buttons = {[{text: 'OK', role: 'cancel'}]}
      />
    </Wrapper>
  )
}
export default MassRollerAssign;
