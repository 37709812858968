//@ts-check

import React, { useContext } from "react";
import { IonLabel, IonList, IonItem } from '@ionic/react';

/* Theme variables */
import '../../theme/variables.css';


/* Icons */
import { swapHorizontal } from "ionicons/icons";

import { i18strings } from "../../functions/i18";
import { MyIonRefresher, Page, Wrapper } from "../../functions/myReactComponents";
import { rroomctx } from "../TabRoller";

const PageRollerRoomList = (props : any) => {
  const rooms = useContext(rroomctx);

  return (
    <Wrapper loading={rooms.loading}>
      <MyIonRefresher onIonRefresh={e => {rooms.reload(); e.detail.complete()}} />

      <Page title={i18strings.title_tab_roller} icon={swapHorizontal}>
        <IonList>{rooms.entries?.map((item) =>
          <IonItem key={item.id} routerLink={'/roller/roomlist/room/' + item.id.toString() + '/' + item.name}>
            <IonLabel>{item.name}</IonLabel>
          </IonItem>
        )}</IonList>
      </Page>

      {rooms.error}
    </Wrapper>
  )
}
export default PageRollerRoomList;
