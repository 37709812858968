import React, { useState, useEffect, useMemo } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonIcon, IonLabel,
        IonPage, IonRouterOutlet, IonTabBar,
        IonTabButton, IonTabs, IonLoading, IonToast, getPlatforms, IonBadge } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { flash, settings, sunny, logOut } from 'ionicons/icons';

import { App as App_ } from "@capacitor/app"

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/style.css';

import { client, xml } from "@xmpp/client";
import loki from 'lokijs';
import axios from 'axios';

import { timeout, randomID, parseBoolean } from './functions/fce-math';
import { parseTag, log } from './functions/fce-string';

import Login, { BareAuth } from "./Login";
import TabHeating from './pages/TabHeating';
import TabRoller from './pages/TabRoller';
import TabSetting from './pages/TabSetting';
import PageSettingInfo from './pages/setting/PageSettingInfo';
import PageSettingDateTime from './pages/setting/PageSettingDateTime';
import { LogoutPopup }  from './functions/myReactComponents';
import DevicesList from './pages/DevicesList';
import { i18strings } from './functions/i18';

import { URL_PREFIX } from './config.json';
import PageMessages from './pages/setting/PageMessages';
import { BwContext, ctxBits, SelectedDeviceContext } from './context/GlobalContext';
import { useConifg } from './functions/PersistentState';
import { SplashScreen } from '@capacitor/splash-screen';

export const DEBUG = false;
export const TIMEOUT_TOAST_SAVED = 500;
export const TIMEOUT_TOAST_ERROR = 2000;
export var msgs: any[] = [];

export function applyTheme(theme : any /*ReturnType<typeof useConifg<"theme">>[0]*/) {
  if (theme === "dark"
  || (theme === "default" && window.matchMedia('(prefers-color-scheme: dark)').matches)
  )
    document.body.classList.add("dark");
  else
    document.body.classList.remove("dark");
}

const ADDR = ".bmr.cz/"
const SIGNOUT_TIMEOUT = 90000;
const WARNING_TIMEOUT = 80000;
let logoutTime : Date | null = null;

document.title = 'BMR HC64';

//Memory Buffer pro kontrolu odeslaných/došlých paketů
const db = new loki('dbBuffer.db');
const dbBufferOutput = db.addCollection('dbBufferOutput');
const dbBufferInput = db.addCollection('dbBufferInput');

const XMPPDOMAIN = 'xmpp.bmr.cz';
const XMPPWEBRESOURCE = 'web';
let XMPPTIMEOUT = 100;
const XMPPServerOptions = { uri: `wss://${XMPPDOMAIN}:5281/websocket`, domain: XMPPDOMAIN };
let XMPPUSERNAME = '';
let XMPPPASSWORD = '';
let XMPP_RECIPIENT = `${XMPPUSERNAME}@${XMPPDOMAIN}/`;

let xmppClient = client(); //Inicializace s parametry je v setTokens()

function RefreshRedis() {
  axios({
    method: 'post',
    url: URL_PREFIX + 'hc64' + ADDR + 'auth/refresh.php',
    headers: {'Content-Type': 'applications/json' },
    data: {
      auth:{
        uid: btoa(unescape(encodeURIComponent(XMPPUSERNAME)))
      }
    }
  });
}

let commlock : null | Promise<void> = null;
async function GetCommLock() {
  let free : (_ : void) => void = () => {}
  while (commlock !== null)
    await commlock;

  commlock = new Promise<void>(res => { free = () => { commlock = null; res(); }; });
  return free;
}

export async function sendXMPPmessage(dvc : string | null, uid, type, fce, param) {
  if (dvc === null) return null;
  let unlock = () => {};
  try {
    unlock = await GetCommLock();

    var msg = `[UID]${uid}[/UID][TYPE]${type}[/TYPE][FCE]${fce}[/FCE][PARAM]${param}[/PARAM]`;

    //Vlozit pozadavek do globálního bufferu
    dbBufferOutput.insert({uid: uid, type: type, fce: fce, param: param});

    await xmppClient.send(xml(
      'message',
      {type: 'chat', id: uid, to: XMPP_RECIPIENT + dvc},
      xml('body', {xmlns: 'jabber:client'}, msg)));

    //-----------------------------------------------
    //Počkat na odpověď ...
    let result: {
      type: "RESULT",
      result: string
    } | null = null;

    for(let i=0;i<30;i++){
      await timeout(XMPPTIMEOUT);
      let r = selectOneRowFromBuffer(dbBufferInput, uid);
      if (r === null) continue;
      try {
        if(r.type === 'RESULT'){
          result = r;
          //Vymazat zpracovaný záznam z bufferu
          deleteRecordFromBuffer(dbBufferOutput, uid);
          deleteRecordFromBuffer(dbBufferInput, uid);

          break;
        }
      }
      catch {}
    }

    RefreshRedis();
    return result;
  } finally {
    unlock();
  }
}

function xmppDisconect() {
  try {
    if (!["online", "connecting"].includes(xmppClient.status)) return;
    xmppClient?.stop();
  } catch(e) {
    console.error(e);
  }
}


const selectOneRowFromBuffer = (buffer, id) => buffer.findOne({ uid: id.toString() });

function deleteRecordFromBuffer(buffer, id){
  buffer.chain().find({ uid: id.toString() }).remove();
}

export function deleteAllRecordFromBuffers(){
  try
  {
    dbBufferOutput.chain().remove();
    dbBufferInput.chain().remove();
  }
  catch {}
}

function presentAlertHC64offline() {
  const alert = document.createElement('ion-alert');
  alert.header = i18strings.warning;
  alert.subHeader = i18strings.error_disconnect;
  alert.message = i18strings.error_checkcommunication;
  alert.buttons = ['OK'];

  document.body.appendChild(alert);

  return alert.present();
}

const App: React.FunctionComponent = () => {
  const [authTokens, setAuthTokens] = useState<null | { uid : string }>(null);
  const [warn, setWarn] = useState(false);
  const [devices, setDevices] = useState<string[]>([]);
  const [xmppOnline, setXmppOnline] = useState(false);
  const [messageIDs, setMessageIDs] = useState<number[]>([]);
  const [bits, setBits] = useState(0);
  const [firmware, setFirmware] = useState(0);
  const [dvc, setDvc] = useState<string | null>(null);

  const hiddenMessages = useConifg("hidden");
  const theme = useConifg("theme");
  const tab = useConifg("tab");

  const unreadCount = useMemo(() => {
    return messageIDs.filter(m => !hiddenMessages.val.includes(m)).length;
  }, [messageIDs, hiddenMessages.val]);

  useEffect(() => {
    applyTheme(theme.val);
  }, [theme.val]);

  useEffect(() => {
    SplashScreen.hide();
    
    axios({
      method: 'post',
      url: URL_PREFIX + 'hc64' + ADDR + 'hc64_webupdateinfo.php',
      headers: { 'Content-Type': 'applications/json' }
    })
    .then(result => {
      if (result.status === 200) {
        msgs = result.data.messages;
        msgs.sort((a, b) => b.id - a.id);
        setMessageIDs(msgs.map(m => m.id));
      } else console.warn(result);
    })
    .catch(console.error);
  }, []);

  useEffect(() => {
    App_.addListener('appStateChange', (state) => {
      if (!XMPPUSERNAME) return;
      if (state.isActive) {
        BareAuth(XMPPUSERNAME, XMPPPASSWORD, () => {})
        .then(res => {
          if (res === false) {
            onClickLogout();
          } else if (logoutTime !== null && new Date() > logoutTime) {
            onClickLogout({ uid : XMPPUSERNAME });
          } else {
            xmppClient.start();
          }
        });
      } else {
        logoutTime = new Date(new Date().valueOf() + SIGNOUT_TIMEOUT);
        xmppClient.stop();
        axios({
          method: 'post',
          url: URL_PREFIX + 'hc64' + ADDR + 'auth/logout.php',
          headers: {'Content-Type': 'applications/json' },
          data: { auth : { uid : XMPPUSERNAME } }
        })
        .then(result => {
          if (result.status === 200) log('Bye, bye ' + XMPPUSERNAME, false);
        })
        .catch(e => {
          console.warn(e);
        });
      }
    });

    return () => { App_.removeAllListeners(); };
  }, []);

  const setTokens = (data : { uid : string, passwd: string, timeout: number } | undefined) => {
    setAuthTokens(data ?? null);

    const xmpp_onStanza //: typeof xmppClient.on<"stanza"> extends (_:"stanza", a : infer T) => void ? T : never
    = async (stanza) => {
      if (stanza.is('message')) {
        let body = stanza.getChild('body');
        if (body)
        {
          var msg = body.text();
          var uid = parseTag(msg, 'UID'); if (!uid) return;
          var type = parseTag(msg, 'TYPE'); if (!type) return;
          var result = parseTag(msg, 'RESULT'); if (!result) return;

          //Vlozit pozadavek do globálního dbBufferInput
          dbBufferInput.insert({uid: uid, type: type, result: result});
        }
      }
      else if (stanza.is('presence')) {
        if (stanza.attrs.from) {
          let dvc = stanza.attrs.from.includes("/") ? stanza.attrs.from.split("/").pop() : "";
          if(dvc !== 'web') {
            setDevices(old => {
              if (old.some(v => v === dvc)) return old;
              else return [...old, dvc];
            });
          }
        }
      }
    };

    if(data === undefined)
    {
      xmppClient.removeAllListeners();
      xmppDisconect();
      setXmppOnline(false);
      return;
    }
    XMPPUSERNAME = data.uid;
    XMPPPASSWORD = data.passwd;
    XMPPTIMEOUT = data.timeout;

    if (data.uid.length === 0 || data.passwd.length === 0) return onClickLogout();
    XMPP_RECIPIENT = `${data.uid}@${XMPPDOMAIN}/`;

    if(xmppClient.status === 'offline')
    {
      xmppClient = client({
        service: XMPPServerOptions.uri,
        domain: XMPPServerOptions.domain,
        resource: XMPPWEBRESOURCE,
        username: data.uid,
        password: data.passwd,
      });

      xmppClient.on('error', (err) => {
        console.error(err);
        xmppDisconect();
      });
      xmppClient.on('offline', () => setXmppOnline(false));
      xmppClient.on('online', () => {
        setXmppOnline(true);
        xmppClient.send(xml('presence', {id: '1', status: XMPPWEBRESOURCE + 'available'}));
      });
      xmppClient.on('stanza', xmpp_onStanza);

      xmppClient.start().catch(console.error);
    }
  }

  const onClickLogout = (auth=authTokens) => {
    xmppDisconect();
    if (dvc) disconnectDevice();
    setAuthTokens(null);
    setDvc(null);
    setDevices([]);
    if (!auth) return;

    var bodyFormData = {
      auth:{
        uid: auth.uid
      }
    }

    axios({
      method: 'post',
      url: URL_PREFIX + 'hc64' + ADDR + 'auth/logout.php',
      headers: {'Content-Type': 'applications/json' },
      data: bodyFormData
    })
    .then(result => {
      if (result.status === 200) log('Bye, bye ' + bodyFormData.auth.uid, false);
    })
    .catch(console.warn);

    XMPPUSERNAME = "";
    XMPPPASSWORD = "";
    return <></>;
  }

  const slowall = async (param: string[], targetDvc: string) => {
    let ret: {
      type: "RESULT";
      result: string;
    }[] = [];
    for (let i = 0; i < param.length; i++) {
      let res = await sendXMPPmessage(dvc, randomID(), 'get', param[i], '');
      if (res == null || targetDvc !== dvc) return null;
      ret.push(res);
    }
    return ret;
  }

  // Check if selected device is online
  useEffect(() => {
    if (dvc === null) return;

    deleteAllRecordFromBuffers();
    setBits((x) => x | ctxBits.loading);

    let q = ['/unitSettings', '/loadHDO', '/getKCNET', '/wtr01Settings', '/windSensorStatus', '/version', '/numOfRooms'];
    slowall(q, dvc)
    .then((cfg) => {
      if (cfg === null) return;
      let res = (bits &~ ctxBits.dvcDep);
      if (cfg[0].result[0] === "1")       res |= ctxBits.backcompat;
      if (cfg[0].result[8] === "1")       res |= ctxBits.roller;
      if (cfg[0].result[1] !== "0")       res |= ctxBits.cooling;
      if (cfg[1].result[0] !== "0")       res |= ctxBits.useHdo;
      if (parseInt(cfg[2].result) > 1)    res |= ctxBits.useKcnet;
      if (parseBoolean(cfg[3].result[0])) res |= ctxBits.useWtr;
      if (parseBoolean(cfg[4].result[0])) res |= ctxBits.useWs;
      if (parseInt(cfg[6].result) !== 0)  res |= ctxBits.heating;
      setBits(res &~ ctxBits.loading);
      setFirmware(parseInt(cfg[5].result, 16));
    })
    .catch(e => {
      console.error(e);
      disconnectDevice(false);
    });
  }, [dvc]);

  useEffect(() => {
    let tmpBits = 0;
    if (xmppOnline) tmpBits |= ctxBits.xmppOnline;
    if (getPlatforms().includes("desktop")) tmpBits |= ctxBits.desktop;
    setBits(b => (b &~ (ctxBits.xmppOnline | ctxBits.desktop)) | tmpBits);
  }, [xmppOnline]);

  const disconnectDevice = (userInvoked: boolean = true) => {
    setBits((b) => b &~ ctxBits.dvcDep);
    if (!userInvoked) presentAlertHC64offline();
    setDvc(null);
  }

  return (
  <IonApp>
    <IonReactRouter>
    <BwContext.Provider value={{bits, setBits}}>
    <SelectedDeviceContext.Provider value={dvc}>

    {authTokens === null
    ? <Login setAuthTokens={setTokens} loggedIn={authTokens !== null} />
    : dvc === null
    ? <DevicesList
      account={authTokens.uid}
      devices={devices}
      setDvc={setDvc}
      logout={() => onClickLogout()}
      tab={`/${tab.val}`}
    />
    : <IonPage>
      {xmppOnline &&
       <LogoutPopup showWarning={()=>setWarn(true)} logout={onClickLogout} hideWarning={() => setWarn(false)} signoutTime={SIGNOUT_TIMEOUT} warningTime={WARNING_TIMEOUT}/>
      }
        <IonTabs>
          <IonRouterOutlet animated={false}>
            <Route exact path="/" render={() => <Redirect to="/login"/>}/>
            <Route exact path="/login" render={() => <Login setAuthTokens={setTokens} loggedIn={authTokens !== null} />} />

            <Route exact path="/deviceslist" render={()=>
              <DevicesList
                account={authTokens.uid}
                devices={devices}
                setDvc={setDvc}
                logout={() => onClickLogout()}
                tab={`/${tab}`}
              />}
            />

            <Route path="/setting" exact render={() => <TabSetting dvcdis={disconnectDevice} unread={messageIDs} />} />
            <Route path="/setting/messages" exact render={() => <PageMessages hidden={hiddenMessages.val} setHidden={hiddenMessages.setVal} />} />
            
            {/* mozna by to tady chtelo kontrolovat, jestli je vybrana jednotka  */}
            <Route path="/heating" render={() => <TabHeating dvcdis={disconnectDevice} />} />
            <Route path="/roller" render={() => <TabRoller dvcdis={disconnectDevice} firmware={firmware} />} />
            
            <Route exact path="/setting/info" component={PageSettingInfo} />
            <Route exact path="/setting/datetime" component={PageSettingDateTime} />
            
            <Redirect to="/devicelist" />
          </IonRouterOutlet>

          <IonLoading isOpen={!xmppOnline} message={'Loading...'} duration={50000} />

          <IonTabBar slot="bottom" id="tabbar">
            <IonTabButton tab="heating" href="/heating" disabled={!(bits & ctxBits.heating) || !authTokens || dvc===null || !xmppOnline} onClick={() => tab.setVal("heating")}>
              <IonIcon icon={flash} />
              <IonLabel>{i18strings.subtitle_tab_heating}</IonLabel>
            </IonTabButton>
            <IonTabButton tab="roller" href="/roller" disabled={!(bits & ctxBits.roller) || !authTokens || dvc===null || !xmppOnline} onClick={() => tab.setVal("roller")}>
              <IonIcon icon={sunny} />
              <IonLabel>{i18strings.title_tab_roller_short}</IonLabel>
            </IonTabButton>
            <IonTabButton tab="setting" href="/setting" disabled={!authTokens} onClick={() => tab.setVal("setting")}>
              <IonIcon icon={settings} />
                {unreadCount && authTokens ? <IonBadge color="warning">{unreadCount}</IonBadge> : ""}
              <IonLabel>{i18strings.title_tab_settings}</IonLabel>
            </IonTabButton>
            <IonTabButton tab="logout" onClick={()=>onClickLogout()} disabled={!authTokens}>
              <IonIcon icon={logOut} color='danger' />
              <IonLabel>{i18strings.title_tab_logout}</IonLabel>
            </IonTabButton>
          </IonTabBar>

          </IonTabs>
      <IonToast isOpen={warn} onDidDismiss={() => setWarn(false)} message={i18strings.login_timeout}
        position="bottom" duration={9900} buttons={[{text: 'OK', role: 'cancel'}]} />
    </IonPage>}

    </SelectedDeviceContext.Provider>
    </BwContext.Provider>
    </IonReactRouter>
  </IonApp>
)};

export default App;
