//@ts-check

import React, { useState } from "react";
import { RefresherEventDetail } from '@ionic/core';
import {  } from '../../functions/fce-string';

import { IonLabel, IonText, IonList, IonItem } from '@ionic/react';

/* Theme variables */
import '../../theme/variables.css';

/* Icons */
import { informationCircle } from "ionicons/icons";

import { useDataXMPP } from '../../functions/fetchXMPP';
import { i18strings } from "../../functions/i18";
import { MyIonRefresher, Help, Page, Wrapper } from "../../functions/myReactComponents";
import { useHandleXmpp } from "../../functions/handleXmpp";

const PageHeatingHDO = (props : any) => {
  const [showLoading, setShowLoading] = useState(true);

  const defaultXmppObject = {
    typefce: 'get',
    fce: '/loadHDO',
    param: ''
  }
  const [hdo, doFetchXmpp] = useDataXMPP(
    defaultXmppObject,
    i18strings.menu_heating_hdo_unknown,
  );

  const [showHelp, setShowHelp] = useState(false);
  const [isHDO, setIsHDO] = useState('');

  useHandleXmpp(hdo.isLoading, hdo.data, (resultXmpp) => {
    let result = i18strings.menu_heating_hdo_unknown;
    try {
      if(resultXmpp.length === 1) {
        result = [
          i18strings.menu_heating_hdo_disabled,
          i18strings.menu_heating_hdo_low,
          i18strings.menu_heating_hdo_hight
        ][resultXmpp[0]]
      }
    } catch {
      result = i18strings.menu_heating_hdo_unknown;
    } finally {
      setIsHDO(result);
      setShowLoading(false);
    }
  });

  const doXmppRefresh = (e : CustomEvent<RefresherEventDetail>) => {
    doFetchXmpp(
      defaultXmppObject,
    );
    e.detail.complete();
  }

  return (
    <Wrapper loading={showLoading} help={() => setShowHelp(true)}>
      <MyIonRefresher onIonRefresh={doXmppRefresh} />

      <Help isOpen={showHelp} onDidDismiss={e => setShowHelp(false)}>
        <p>{i18strings.help_heating_hdo}</p>
      </Help>

      <Page title={i18strings.menu_heating_tariff} icon={informationCircle}>
        <IonList>
          <IonItem lines="none">
            <IonLabel>
              <IonText>{isHDO}</IonText>
            </IonLabel>
          </IonItem>
        </IonList>
      </Page>

      {hdo.err}
    </Wrapper>
  )
}
export default PageHeatingHDO;  
