//@ts-check

import React, { useState } from "react";
import { RefresherEventDetail } from '@ionic/core';

import { IonIcon, IonText,
         IonToast, IonButton, IonList, IonItem,
         IonRange, IonLabel
} from '@ionic/react';

/* Theme variables */
import '../../theme/variables.css';


/* Icons */
import { thermometer, handRight } from "ionicons/icons";

import { useDataXMPP } from '../../functions/fetchXMPP';
import { RouteComponentProps } from "react-router";
import { prepareID, parseNaN2questionMarks } from "../../functions/fce-string";
import { TIMEOUT_TOAST_SAVED } from "../../App";
import { i18strings } from "../../functions/i18";
import { MyIonRefresher, Help, Page, Wrapper } from "../../functions/myReactComponents";
import { useHandleXmpp } from "../../functions/handleXmpp";

interface UserDetailPageProps extends RouteComponentProps<{
  id: string;
  name: string;
}> {}

const PageHeatingManualSet : React.FunctionComponent <UserDetailPageProps> = ({match}) => {
  const [showLoading, setShowLoading] = useState(true);
  const defaultXmppObject = {
    typefce: 'get',
    fce: '/wholeRoom',
    param: prepareID(parseInt(match.params.id))
  }
  const [wholeRoom, doFetchXmpp] = useDataXMPP(
    defaultXmppObject,
    '',
  );

  const [showHelp, setShowHelp] = useState(false);
  const [showToastSaved, setShowToastSaved] = useState(false);
  const [actualTemp, setActualTemp] = useState(0);
  const [setTemp, setSetTemp] = useState(0);
  const [userOfset, setUserOfset] = useState(NaN);
  const [maxUserOfset, setMaxUserOfset] = useState(0);
  const [unsaved, setUnsaved] = useState(0);

  useHandleXmpp(wholeRoom.isLoading, wholeRoom.data, (resultXmpp) => {
    try {
      if(resultXmpp.length >= 14) {
        const room = {
          actualTemp: parseFloat(resultXmpp.substr(14,5)),
          setTemp: parseFloat(resultXmpp.substr(19,3)),
          setTempAll: parseFloat(resultXmpp.substr(22,5)),
          userOfset: parseFloat(resultXmpp.substr(27,5)),
          maxUserOfset: parseFloat(resultXmpp.substr(32,4))
        };
        setActualTemp(room.actualTemp);
        setSetTemp(room.setTemp);
        setUserOfset(room.userOfset);
        setMaxUserOfset(room.maxUserOfset);
        setShowLoading(false);
        setUnsaved(0);
      };
    }
    catch {}
    finally {}
  });

  const doXmppRefresh = (e : CustomEvent<RefresherEventDetail>) => {
    doFetchXmpp(defaultXmppObject);
    e.detail.complete();
  }

  const handleOnChange = (e) => {
    if (isNaN(userOfset)) return;
    setUserOfset(e.detail.value);
    setUnsaved(u => 2 | (u >> 1));
  }

  const handleOnClickOK = () => {
    var id = prepareID(parseInt(match.params.id));
    var temp = (() => {
      var t = userOfset * 10;
      const neg = t < 0;

      if (neg) t = -t;
      var res = t.toString().padStart(4, "0");
      if (neg) res = res.replace(/^0?/, "-");
      return res;
    })();

    const saveXmppObject = {
      typefce: 'SET',
      fce: '/saveManualTemp',
      param: id + temp
    }
    doFetchXmpp(saveXmppObject);

    setShowToastSaved(true);
    setUnsaved(2);
  }

  return (
    <Wrapper loading={showLoading} help={() => setShowHelp(true)}>
      <MyIonRefresher onIonRefresh={doXmppRefresh}></MyIonRefresher>

      <Help isOpen={showHelp} onDidDismiss={e => setShowHelp(false)}>
        <p>{i18strings.help_heating_manualset}</p>
      </Help>

      <IonToast
        isOpen={showToastSaved}
        onDidDismiss={() => setShowToastSaved(false)}
        message = {i18strings.action_settings_saved}
        duration={TIMEOUT_TOAST_SAVED}
      />

      <Page title={i18strings.menu_heating_manualchange} icon={handRight} unsaved={!!(unsaved & 1)}>
        <IonList>
          <IonItem color='primary'>{match.params.name}</IonItem>
          <IonItem>
            <IonLabel>{i18strings.temperature_actual}:</IonLabel>
            <IonText>{parseNaN2questionMarks(actualTemp)}°C</IonText>
          </IonItem>
          <IonItem>
            <IonLabel>{i18strings.temperature_program}:</IonLabel>
            <IonText>{parseNaN2questionMarks(setTemp)}°C</IonText>
          </IonItem>
          <IonItem>
            <IonLabel>{i18strings.temperature_maxoffset}:</IonLabel>
            <IonText>{parseNaN2questionMarks(maxUserOfset)}°C</IonText>
          </IonItem>
          <IonItem>
            <IonLabel>{i18strings.temperature_total}:</IonLabel>
            <IonText>{parseNaN2questionMarks(setTemp + userOfset)}°C</IonText>
          </IonItem>
          <IonItem lines="none">
            <IonLabel>{i18strings.temperature_manualchange}:</IonLabel>
            <IonText>{parseNaN2questionMarks(userOfset)}°C</IonText>
          </IonItem>
          <IonItem>
            <IonIcon size="small" slot="start" icon={thermometer}/>
            <IonRange min={-maxUserOfset} max={maxUserOfset} step={0.5} value={userOfset} onIonChange={handleOnChange} />
            <IonIcon slot="end" icon={thermometer}/>
          </IonItem>
        </IonList>
        <IonButton disabled={wholeRoom.err !== null || wholeRoom.isLoading || !(unsaved & 1)} expand="block" onClick={handleOnClickOK}>{i18strings.action_save}</IonButton>
      </Page>

      {wholeRoom.err}
    </Wrapper>
  )
}
export default PageHeatingManualSet;
