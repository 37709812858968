import { createContext } from 'react';

export const BwContext = createContext({bits: 0, setBits: (v: number) => {}});
export const ctxBits = {
  backcompat: 1,
  heating: 2,
  roller: 4,
  cooling: 8,
  useHdo: 16,
  useKcnet: 32,
  useWtr: 64,
  useWs: 128,
  dvcDep: 255,
  desktop: 256,
  xmppOnline: 512,
  loading: 1024,
}

export const SelectedDeviceContext = createContext<string | null>(null);
