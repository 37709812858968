//@ts-check

import React, { useState, useContext, useEffect } from "react";
import { RefresherEventDetail } from '@ionic/core';

import { IonIcon, IonText,
         IonToast, IonButton, IonList, IonItem,
         IonRange, IonLabel, IonSelect, IonSelectOption
} from '@ionic/react';

/* Theme variables */
import '../../theme/variables.css';


/* Icons */
import { thermometer } from "ionicons/icons";

import { useDataXMPP } from '../../functions/fetchXMPP';
import { prepareID } from "../../functions/fce-string";
import { TIMEOUT_TOAST_SAVED } from "../../App";
import { BwContext, ctxBits } from "../../context/GlobalContext";
import { i18strings } from "../../functions/i18";
import { MyIonRefresher, Help, Page, Wrapper } from "../../functions/myReactComponents";
import { useHandleXmpp } from "../../functions/handleXmpp";

const PageCoolingSet = (props: any) => {
  const [showLoading, setShowLoading] = useState(true);
  const bits = useContext(BwContext);

  const defaultXmppObject = {
    typefce: 'get',
    fce: '/unitSettings',
    param: ''
  }
  const [settings, doFetchXmpp] = useDataXMPP(defaultXmppObject, '');
  const [showHelp, setShowHelp] = useState(false);
  const [showToastSaved, setShowToastSaved] = useState(false);
  const [cooling, setCooling] = useState({isActive: 0, outTemp: 0, hyst: 0});
  const [enabledTemp, setEnabledTemp] = useState(false);
  const [unsaved, setUnsaved] = useState(0);

  useHandleXmpp(settings.isLoading, settings.data, (resultXmpp) => {
    try {
      let isActive = parseInt(resultXmpp[1]); //Cooling 0 = ne, 1 = WTR_01, 2 = HCS64

      //pouze pro WTR01 jsou k dispozici teploty
      let outTemp = 0;
      let hyst = 0;
      if(isActive === 1) {
        setEnabledTemp(true);
        outTemp = parseInt((resultXmpp).substr(2,2));  //vnější teplota aktivace chlazení WTR_01 (2 znaky) – v celých °C
        hyst = parseInt((resultXmpp).substr(4,2));     //hystereze teplota aktivace chlazení WTR_01 (2 znaky) – v celých °C
      }

      setCooling({
        isActive: isActive,
        outTemp: outTemp,
        hyst: hyst
      });
    }
    catch(e) {
      console.error(e);
      setCooling({
        isActive: 0,
        outTemp: 0,
        hyst: 0
      });
    }
    finally {
      setShowLoading(false);
      setUnsaved(0);
    }
  });

  useEffect(()=>{
    setTimeout(()=>setUnsaved(u => u | 2), 0);
  }, [cooling]);

  const doXmppRefresh = (e : CustomEvent<RefresherEventDetail>) => {
    doFetchXmpp(defaultXmppObject);
    e.detail.complete();
  }

  const handleOnChangeCoolMode = (e) => {
    if(e.detail.value === 1) {
      setEnabledTemp(true);
      setCooling(prevState => ({
        ...prevState,
        isActive: e.detail.value,
      }));
    }
    else {
      setEnabledTemp(false);
      setCooling(prevState => ({
        ...prevState,
        isActive: e.detail.value,
        outTemp: 0,
        hyst: 0
      }));
    }
    setUnsaved(u => u | (u >> 1));
  }

  const handleOnChangeTempOut = (e) => {
    setCooling(prevState => ({
      ...prevState,
      outTemp: e.detail.value
    }));
    setUnsaved(u => u | (u >> 1));
  }

  const handleOnChangeHyst = (e) => {
    setCooling(prevState => ({
      ...prevState,
      hyst: e.detail.value
    }));
    setUnsaved(u => u | (u >> 1));
  }

  const handleOnClickOK = () => {
    let p = cooling.isActive
     + prepareID(cooling.outTemp)
     + prepareID(cooling.hyst);

    const saveXmppObject = {
      typefce: 'SET',
      fce: '/setCoolSettings',
      param: p
    }
    doFetchXmpp(saveXmppObject);

    if (cooling.isActive) bits.setBits(bits.bits | ctxBits.cooling);
    else bits.setBits(bits.bits &~ ctxBits.cooling);
    setShowToastSaved(true);
    setUnsaved(2);
  }

  return (
    <Wrapper loading={showLoading} help={() => setShowHelp(true)}>
      <MyIonRefresher onIonRefresh={doXmppRefresh} />

      <Help isOpen={showHelp} onDidDismiss={e => setShowHelp(false)}>
        <p>{i18strings.help_cooling_set}</p>
        <ul>
          <li>{i18strings.help_cooling_set1}</li>
          <li>{i18strings.help_cooling_set2}</li>
          <li>{i18strings.help_cooling_set3}</li>
        </ul>
        <p>{i18strings.help_cooling_set4}</p>
        <p>{i18strings.help_cooling_set5}</p>
        <p>{i18strings.help_cooling_set6}</p>
      </Help>

      <IonToast
        isOpen={showToastSaved}
        onDidDismiss={() => setShowToastSaved(false)}
        message={i18strings.action_settings_saved}
        duration={TIMEOUT_TOAST_SAVED}
      />

      <Page title={i18strings.menu_cooling_setting} icon={thermometer} unsaved={!!(unsaved & 1)}>
        <IonButton disabled={settings.err !== null || settings.isLoading || !(unsaved & 1)} expand="block" onClick={handleOnClickOK}>{i18strings.action_save}</IonButton>
        <IonList>
          <IonItem>
            <IonLabel>{i18strings.menu_cooling_set_modes}:</IonLabel>
              <IonSelect value={cooling.isActive} okText="OK" cancelText="Zrušit" onIonChange={handleOnChangeCoolMode}>
                <IonSelectOption value={0}>{i18strings.menu_cooling_set_mode0}</IonSelectOption>
                <IonSelectOption value={1}>{i18strings.menu_cooling_set_mode1}</IonSelectOption>
                <IonSelectOption value={2}>{i18strings.menu_cooling_set_mode2}</IonSelectOption>
              </IonSelect>
            </IonItem>
            {enabledTemp ?
            <IonItem lines="none">
              <IonLabel>{i18strings.menu_cooling_set_exttemp_activation}:</IonLabel><IonText>{cooling.outTemp}°C</IonText>
            </IonItem> :""}
            {enabledTemp ?
            <IonItem>
              <IonIcon size="small" slot="start" icon={thermometer}/>
              <IonRange max={50} value={cooling.outTemp} onIonChange={handleOnChangeTempOut} />
              <IonIcon slot="end" icon={thermometer}/>
            </IonItem> :""}
            {enabledTemp ?
            <IonItem lines="none">
              <IonLabel>{i18strings.menu_cooling_set_hyst}:</IonLabel><IonText>{cooling.hyst}°C</IonText>
            </IonItem> :""}
            {enabledTemp ?
            <IonItem>
              <IonIcon size="small" slot="start" icon={thermometer}/>
              <IonRange max={5} value={cooling.hyst} onIonChange={handleOnChangeHyst} />
              <IonIcon slot="end" icon={thermometer}/>
            </IonItem> :""}
        </IonList>
        <IonButton disabled={settings.err !== null || settings.isLoading || !(unsaved & 1)} expand="block" onClick={handleOnClickOK}>{i18strings.action_save}</IonButton>
      </Page>

      {settings.err}
    </Wrapper>
  )
}
export default PageCoolingSet;
