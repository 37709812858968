//@ts-check

import React, { useState, useEffect, useReducer, useContext, ReactNode } from "react";

import { sendXMPPmessage, deleteAllRecordFromBuffers } from '../App';
import { randomID } from './fce-math';
import { i18strings } from "./i18";
import { ErrToast } from "./myReactComponents";
import { SelectedDeviceContext } from "../context/GlobalContext";

const dataXmppFetchReducer = (
  state : {
    isLoading : boolean,
    err : null | ReactNode,
    data : string
  },
  action : { type : "FETCH_SUCCESS" | "FETCH_FAILURE", payload : string } | { type : 'FETCH_INIT' }
) => {
  switch (action.type) {
    case 'FETCH_INIT':
      return {
        ...state,
        isLoading: true,
        err: null
      };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        isLoading: false,
        err: null,
        data: action.payload
      };
    case 'FETCH_FAILURE':
      return {
        ...state,
        isLoading: false,
        err: <ErrToast isOpen message={action.payload} />,
      };
    default:
      throw new Error();
  }
};

export const useDataXMPP = (initialXMPPparam : {
  typefce : string,
  fce : string,
  param : string
}, initialData : string, ...depend : unknown[]) => {
  const [xmppParam, setXmppParam] = useState(initialXMPPparam);
  const [state, dispatch] = useReducer(dataXmppFetchReducer, {
    isLoading: false,
    err: null,
    data: initialData as string
  });
  const dvc = useContext(SelectedDeviceContext);

  useEffect(() => {
    if (xmppParam.param === undefined) return;
    let didCancel = false; 
    
    const fetchData = async () => {
      dispatch({ type: 'FETCH_INIT' });
      
      try {
        deleteAllRecordFromBuffers();
        const result = await sendXMPPmessage(
          dvc,
          randomID(),
          xmppParam.typefce,
          xmppParam.fce,
          xmppParam.param
        )
        if(result === null) throw new Error(i18strings.error_checkcommunication);
        if (!didCancel) dispatch({ type: 'FETCH_SUCCESS', payload: result.result });
      } catch (err) {
        if (!didCancel) dispatch({ type: 'FETCH_FAILURE', payload: (err as any).message });
      }
    };
    fetchData();
    return () => {
      didCancel = true;
    };
  }, [xmppParam, ...depend]);
  return [state, setXmppParam] as const;
};
