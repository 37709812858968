//@ts-check

export function timeout(ms:number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function randomID() {
  return Math.floor(Math.random() * 999999999);
}

export function parseBoolean(value:any){
  switch(value) {
    case true:
    case "true":
    case 1:
    case "1":
    case "on":
    case "yes":
      return true;
    default: 
      return false;
  }
}

const ordering = {
  id: 0,
  name: 1
}

export function roomOrder
(
  _rooms: {name: string, idAirSensor: number, id: number}[],
  order: number = ordering.id
): number[] {
  let rooms: typeof _rooms = JSON.parse(JSON.stringify(_rooms)); // pracuje se s kopií
  let res: number[] = [];

  switch (order) {
    case ordering.name:
      for (let i = 0; i < rooms.length; i++) {
        rooms[i].name = rooms[i].name.toLowerCase();
      }
      rooms.sort((a, b) => {
        return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
      });
      break;
    case ordering.id:
    default:
      rooms.sort((a, b) => a.id - b.id);
      break;
  }

  let floors: number[][] = [];
  rooms.forEach(r => floors[r.idAirSensor] ? floors[r.idAirSensor].push(r.id) : floors[r.idAirSensor] = [r.id]);
  floors[0]?.forEach(r => res.push(r, ...(r === 0 ? [] : floors[r] ?? [])));
  return res;
}
