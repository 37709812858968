import { useEffect, useMemo, useRef, useState } from "react";
import { SortOrder } from "./myReactComponents";
import { Preferences } from "@capacitor/preferences";
import { appId } from "../config.json";
import { ParseUsers, StringifyUsers, emmptyProfile } from "../Login";
import { decrypt } from "./fce-string";

type ValueTypes = {
  theme : "light" | "dark" | "default",
  locale : "en" | "cs",
  sort : SortOrder,
  tab : "heating" | "roller" | "setting",
  hidden : number[],
  users : {
    name : string,
    password : string,
    save : boolean,
    savePwd : boolean
  }[],
  lastUser : string
}

const validators : { [k in keyof ValueTypes] : (val : string | null) => ValueTypes[k] } = {
  theme(val) {
    return val === "light" || val === "dark"
    ? val
    : "default";
  },

  locale(val) {
    return val === "en" ? val : "cs";
  },

  sort(val) {
    val as Exclude<SortOrder, SortOrder.alphabet | SortOrder.id>;
    return val === `${SortOrder.alphabet}`
      ? SortOrder.alphabet
      : SortOrder.id;
  },

  tab(val) {
    return val === "roller" || val === "setting" ? val : "heating";
  },

  hidden(val) {
    if (val === null || val.length === 0) return [];
    return val.split(",").map(v => Number.parseInt(v)).filter(v => !isNaN(v));
  },

  users(val) {
    if (val === null)
      return LoadOldUsers();
    
    return ParseUsers(val);
  },

  lastUser(val) {
    return val ?? localStorage.getItem(`${appId}.uid`) ?? "";
  },
}

function id(v : string) {
  return v;
}

const writers : { [k in keyof ValueTypes] : (val : ValueTypes[k]) => string } = {
  theme: id,
  locale: id,
  tab: id,
  
  sort(val) {
    return val.toFixed(0);
  },

  hidden(val) {
    return val.filter(v => !isNaN(v)).join(',');
  },

  users(val) {
    return StringifyUsers(val);
  },

  lastUser: id,
}

export function useConifg<K extends keyof ValueTypes>(key : K) {
  const def = useMemo(() => validators[key](null), [key]);
  const [val, setVal] = useState(def);
  const mounted = useRef(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    mounted.current = true;
    Preferences.get({ key }).then(({ value }) => {
      if (!mounted.current) return;
      if (value === null && key !== "users" && key !== "lastUser")
        value = localStorage.getItem(`${appId}.${key}`);
      
      setLoaded(true);
      setVal(validators[key](value));
    });

    return () => {
      mounted.current = false;
    }
  }, []);

  function ForceSave(val : ValueTypes[K]) {
    Preferences.set({ key, value : writers[key](val) });
    setVal(val);
  }

  useEffect(() => {
    ForceSave(val);
  }, [val]);

  return { val, setVal, ForceSave, loaded };
}

function LoadOldUsers() : ValueTypes["users"] {
  const sel = localStorage.getItem(appId + '.uid');
  const pwd = localStorage.getItem(appId + '.pwd')?.split(';').map(v => decrypt(v)) ?? [];
  const users = localStorage.getItem(appId + '.users')?.split(';').map(v => decrypt(v)) ?? [];

  if (users.length === 0 && pwd.length === 1 && sel !== null) {
    // backward compatability
    return [
      { name: sel, password: pwd[0], save: true, savePwd: true },
      emmptyProfile(),
    ];
  } else if (users.length === pwd.length) {
    let res : ValueTypes["users"] = [];
    for (let i = 0; i < users.length; i++) {
      res.push({ name: users[i], password: pwd[i], save: true, savePwd: pwd[i] !== "" });
    }
    res.push(emmptyProfile());
    return res;
  } else
    return [emmptyProfile()]
}