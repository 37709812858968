//@ts-check

import React, { FC, Fragment, useContext, useState } from "react";
import { getArrOfNames } from '../../functions/fce-string';

import { IonLabel, IonToast, IonButton, IonSlides, IonSlide, IonAlert, IonCardHeader, IonCard, IonChip, IonIcon } from '@ionic/react';

/* Theme variables */
import '../../theme/variables.css';


/* Icons */
import { optionsOutline, swapHorizontal } from "ionicons/icons";
import { useDataXMPP } from '../../functions/fetchXMPP';
import { prepareID } from "../../functions/fce-string";
import { TIMEOUT_TOAST_SAVED, TIMEOUT_TOAST_ERROR, sendXMPPmessage } from "../../App";
import { i18strings } from "../../functions/i18";
import { Page, Wrapper, Help, RegimesAssignment } from "../../functions/myReactComponents";
import { useHandleXmpp } from "../../functions/handleXmpp";
import { SelectedDeviceContext } from "../../context/GlobalContext";
import { randomID } from "../../functions/fce-math";
import { useMultiRoomHeatingSelection } from "../../components/RoomSelection";

const MassCoolAssign : FC = () => {
  const [showHelp, setShowHelp] = useState(false);
  const [showToastSaved, setShowToastSaved] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [isError, setIsError] = useState(false);

  const [modeList] = useDataXMPP({typefce: 'get', fce: '/listOfModes', param: ''}, '');  

  const [listRegimes, setListRegimes] = useState<{ id: number, name: string }[]>([]);
  
  const dvc = useContext(SelectedDeviceContext);
  const multiroom = useMultiRoomHeatingSelection();

  const defaultRegimes = [...Array(7)].map((v,i) => {
    return {
      id: i,
      value: -1,
      isFirstDay: false
    }
  });
  const [regimes, setRegimes] = useState(defaultRegimes);
  
  useHandleXmpp(modeList.isLoading, modeList.data , (data) => {
    let result : typeof listRegimes = [];
    try {  
      if(data.length >= 13)
        result = getArrOfNames(data, 13).map((row, i) => { return { id: i, name: row } });
    }
    catch {
      result = [];
    }
    finally {
      setListRegimes(result)
    }
  });

  const slideOpts = {
    initialSlide: 0,
    pager: true,
    stretch: 0,
    speed: 0,
    freemode: true
  };

  const handleOnSelectItem = (e, id:number) => {
    const copy = [...regimes];
    copy[id].value = parseInt(e.detail.value);
    setRegimes(copy);
  }

  const handleOnClickOK = async () => {
    //• ID místnosti (2 znaky)
    //• id režimů jednotlivých dnů (každý režim 2 znaky).  '-1' značí nepřiřazený režim a je doplněn do 21 dnů. 

    let reg = '';
    for(let i = 0; i < Object.keys(regimes).length; i++) {
      reg += prepareID(regimes[i].value);
    };

    {
      let ctrl = reg.split("-");
      ctrl.splice(0, 1);
      if(ctrl.some((v)=>{return v.length !== 1})){
        setIsError(true);
        return;
      }
    }

    for (let room of multiroom.selected()) {
      await sendXMPPmessage(
        dvc,
        randomID(),
        'SET',
        '/saveAssignmentModesCool',
        prepareID(room) + reg
      );
    }

    multiroom.setState(0);
    setShowToastSaved(true);
  }

  return (
    <Wrapper loading={modeList.isLoading} help={() => setShowHelp(true)}>

      <Help isOpen={showHelp} onDidDismiss={e => setShowHelp(false)}>
        <p>{i18strings.help_cooling_roomset}</p>
        <p>{i18strings.help_regime_assignment.split("{ic}").map((v, i, a) => <Fragment key={i}>
          {v}
          {i < a.length - 1 && <IonIcon icon={optionsOutline} style={{fontSize: "1.5em", marginBottom: "-5px"}} />}
        </Fragment>)}</p>
      </Help>

      <IonToast
        isOpen={showToastSaved}
        onDidDismiss={() => setShowToastSaved(false)}
        message={i18strings.action_settings_saved}
        duration={TIMEOUT_TOAST_SAVED}
      />

      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        header = {i18strings.warning}
        message = {i18strings.confirm_delete_settings}
        buttons = {[
          {
            text: i18strings.button_no,
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {}
          },
          {
            text: i18strings.button_yes,
            handler: () => setRegimes(defaultRegimes)
          }
          ]}
      />

      <Page title={i18strings.menu_cooling_masscircuitsetting} icon={swapHorizontal}>
        <IonButton expand="block" onClick={()=>setShowAlert(true)}>{i18strings.button_delete_settings}</IonButton>

        <IonSlides pager={true} options={slideOpts}>
          <IonSlide>
            <IonCard>
              <IonCardHeader>
                <IonChip><IonLabel> {i18strings.menu_cooling_circuitsetting} </IonLabel></IonChip>
              </IonCardHeader>
                <RegimesAssignment
                  regimeAssignments={regimes}
                  defaultRegime={-1}
                  loc="/heating/regimelist"
                  handleSelect={handleOnSelectItem}
                  possibleRegimes={listRegimes}
                />
            </IonCard>
          </IonSlide>
        </IonSlides>

        <IonButton expand="block" disabled={!multiroom.state} onClick={handleOnClickOK}>{i18strings.action_save}</IonButton>
        {multiroom.node}
        <IonButton expand="block" disabled={!multiroom.state} onClick={handleOnClickOK}>{i18strings.action_save}</IonButton>
      </Page>

      {modeList.err}
      <IonToast
        isOpen={isError}
        onDidDismiss={() => setIsError(false)}
        message = {i18strings.menu_heating_previousregime_warning}
        position="bottom"
        duration={TIMEOUT_TOAST_ERROR * 2}
        buttons={[{text: 'OK', role: 'cancel'}]}
      />
    </Wrapper>
    )
  }
  export default MassCoolAssign;  
