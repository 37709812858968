//@ts-check

import React, { useState } from "react";
import { RefresherEventDetail } from '@ionic/core';

import { IonLabel, IonToast, IonButton, IonList, IonItem, IonDatetime } from '@ionic/react';

/* Theme variables */
import '../../theme/variables.css';

/* Icons */
import { time } from "ionicons/icons";

/* XMPP fetch */
import { useDataXMPP } from '../../functions/fetchXMPP';
import { ISOdateTimeFormat2HC64, HC64dateTimeFormat2ISO, TimezonelessNow } from "../../functions/fce-string";
import { TIMEOUT_TOAST_SAVED } from "../../App";
import { i18strings } from "../../functions/i18";
import { MyIonRefresher, Help, Page, Wrapper } from "../../functions/myReactComponents";
import { useHandleXmpp } from "../../functions/handleXmpp";

const PageSettingDateTime = (props : any) => {
  const [showLoading, setShowLoading] = useState(true);
  const [unsaved, setUnsaved] = useState(false);

  const defaultXmppObject = {
    typefce: 'get',
    fce: '/loadDateTime',
    param: ''
  }
  const [date, doFetchXmpp] = useDataXMPP(
    defaultXmppObject,
    '',
  );  
  
  const [showHelp, setShowHelp] = useState(false);
  const [showToastSaved, setShowToastSaved] = useState(false);
  const [dateTime, setDateTime] = useState('');

  useHandleXmpp(date.isLoading, date.data, (resultXmpp) => {
    let result = '';

    try {
      if(resultXmpp.length === 15) {
        //result = resultXmpp.substring(0,10) + "T" + resultXmpp.substring(10);
        result = HC64dateTimeFormat2ISO(resultXmpp);
      }
    } catch {
      result = '';
    } finally {
      setDateTime(result);
      setShowLoading(false);
      setTimeout(()=>setUnsaved(false), 0);
    }
  });
  
  const doXmppRefresh = (e : CustomEvent<RefresherEventDetail>) => {
    doFetchXmpp(defaultXmppObject);
    e.detail.complete();
  }

  const handleOnClickOK = () => {
    doFetchXmpp({typefce: 'SET', fce: '/saveDateTime', param: ISOdateTimeFormat2HC64(dateTime)});

    setShowToastSaved(true);
    setUnsaved(false);
  }

  const handleOnClickNow = () => {
    setDateTime(TimezonelessNow());
    setUnsaved(true);
  }
  
  return (
    <Wrapper loading={showLoading} help={() => setShowHelp(true)}>
      <MyIonRefresher onIonRefresh={doXmppRefresh} />
      
      <Help isOpen={showHelp} onDidDismiss={e => setShowHelp(false)}>
        <p>{i18strings.menu_setting_datetime_text}</p>
      </Help>

      <IonToast
        isOpen={showToastSaved}
        onDidDismiss={() => setShowToastSaved(false)}
        message={i18strings.action_settings_saved}
        duration={TIMEOUT_TOAST_SAVED}
      />

      <Page title={i18strings.menu_setting_datetime} icon={time} unsaved={unsaved}>
        <IonList>
          <IonItem>
            <IonLabel>{i18strings.menu_setting_datetime1}</IonLabel>
            <IonDatetime max="2050" doneText='OK' cancelText='Zrušit' value={dateTime} onIonChange={(e) => {setDateTime(e.detail.value as string); setUnsaved(true)}} firstDayOfWeek={1} />
          </IonItem>
        </IonList>
        <IonButton disabled={date.err !== null || date.isLoading} expand="block" onClick={handleOnClickNow}>{i18strings.menu_setting_datetime_getcurrenttime}</IonButton>
        <IonButton disabled={date.err !== null || date.isLoading || !unsaved} expand="block" onClick={handleOnClickOK}>{i18strings.action_save}</IonButton>
      </Page>

      {date.err}
    </Wrapper>
  )
}
export default PageSettingDateTime;  
