import { useEffect } from "react"
import { useDataXMPP } from "./fetchXMPP";
import { getArrOfNames } from "./fce-string";

export const useHandleXmpp = (
  isLoading : boolean,
  data : string,
  callback : (val : string) => void
) => {
  useEffect(() => {
    if ((isLoading || !data) && data !== undefined) return;
    if(data === 'true') return; // ?
    callback(data);
  }, [isLoading, data])
}

export const useListRooms = (setList, setLoading) => {
  const defaultXmppObject = {
    typefce: 'get',
    fce: '/listOfRooms',
    param: ''
  };
  const [rooms, doFetchXmpp] = useDataXMPP(
    defaultXmppObject,
    '',
  );

  useHandleXmpp(rooms.isLoading, rooms.data, (resultXmpp) => {
    let result : { id : number, name : string }[] = [];
    try {  
      if(resultXmpp.length >= 13) {
        result = getArrOfNames(resultXmpp, 13).map((row, i) => ({ id: i, name: row }));
        setLoading(false);
      }
    }
    catch {
      result = [];
    }
    finally {
      setList(result);
    }
  });

  const doXmppRefresh = (e) => {
    doFetchXmpp(defaultXmppObject);
    e.detail.complete();
  }

  return [rooms.err, doXmppRefresh];
}