//@ts-check

import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonList, IonItem, IonLabel, IonButton,
  IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCardSubtitle, IonSpinner, IonFooter} from '@ionic/react';
import React, { useState, useEffect } from 'react';
import { i18strings } from '../functions/i18';
import "../login.css";
import "../custom-global-themes.css";
import { Help } from "../functions/myReactComponents";
import { useHistory } from 'react-router';

let rdvc: string[] = [];
const DevicesList = (props: {
  account: string,
  devices: string[],
  setDvc: (dvc: string) => void,
  logout: () => void,
  tab: string
}) => {
  const [showHelp, setShowHelp] = useState(false);
  const [arr, setArr] = useState(props.devices);
  const h = useHistory();

  useEffect(() => {
    setArr(props.devices);
    if (props.devices.length === 1 && rdvc !== props.devices) {
      setTimeout(() => {
        props.setDvc(props.devices[0]);
        h.push(props.tab);
        rdvc = props.devices;
      }, 0);
    }
  },[props.devices]);

  const handleOnClick = (e) => {
    props.setDvc(e.target.innerText);
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle slot="start">{i18strings.title_devices}</IonTitle>
          {arr.length === 0 ? 
            <IonSpinner name="circles" />
          :""}
          <IonButtons slot="end">
            <IonButton onClick={() => setShowHelp(true)}>?</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      
      <IonContent>
      
      <IonCard className="welcome-card" style={{margin: "12px"}}>
        <IonCardHeader class="compact">
          <IonCardTitle>{i18strings.menu_devicelist_title}</IonCardTitle>
          <IonCardSubtitle>{i18strings.menu_devicelist_subtitle} '{props.account}'<br></br>{i18strings.menu_devicelist_text}</IonCardSubtitle>
        </IonCardHeader>
        <IonCardContent class="compact">
          <IonList>
          {arr.map((item, index) => (
            <IonItem button key={index} routerLink={props.tab} onClick={handleOnClick} >
              <IonLabel>{item}</IonLabel>
            </IonItem>
          ))}
          </IonList>
          
        </IonCardContent>
      </IonCard>
      <p></p>

      <Help isOpen={showHelp} onDidDismiss={e => setShowHelp(false)}>
        <p>{i18strings.help_devicelist}</p>
      </Help>

      </IonContent>
      <IonFooter>
        <IonButton color="danger" expand="block" onClick={props.logout}>{i18strings.title_tab_logout}</IonButton>
      </IonFooter>
    </IonPage>
  );
};

export default DevicesList;
