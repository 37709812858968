//@ts-check

import React, { useState, useEffect } from "react";
import { RefresherEventDetail } from '@ionic/core';

import { IonLabel, IonIcon, IonText, IonList, IonItem } from '@ionic/react';

/* Theme variables */
import '../../theme/variables.css';

/* Icons */
import { thunderstorm, alertCircleOutline } from "ionicons/icons";

import { useDataXMPP } from '../../functions/fetchXMPP';
import { parseBoolean } from "../../functions/fce-math";
import { i18strings } from "../../functions/i18";
import { MyIonRefresher, Help, Page, Wrapper } from "../../functions/myReactComponents";
import { useHandleXmpp } from "../../functions/handleXmpp";
import { addMinutes, format } from "date-fns";
import { parseNaN2questionMarks } from "../../functions/fce-string";

const PageRollerWind = (props : any) => {
  const [showLoading, setShowLoading] = useState(true);

  const defaultXmppObject = {
    typefce: 'get',
    fce: '/windSensorStatus',
    param: ''
  }
  const [timer, setTimer] = useState(0);
  const [status, doFetchXmpp] = useDataXMPP(defaultXmppObject, 'nezjištěno', timer);

  const [showHelp, setShowHelp] = useState(false);
  const [isSensorError, setIsSensorError] = useState(false);
  const [speed, setSpeed] = useState(0);
  const [isAlarm, setIsAlarm] = useState(false);
  const [alarmSpeed, setAlarmSpeed] = useState(0);
  const [alarmDate, setAlarmDate] = useState('');

  useHandleXmpp(status.isLoading, status.data, (resultXmpp) => {
    try {
      setSpeed(0);
      let _speed = parseInt(resultXmpp.substr(4, 3));
      if(_speed === 255) setIsSensorError(true);
      else setSpeed(_speed);

      setIsAlarm(parseBoolean(resultXmpp[7]));

      let _speedAlarm = parseInt(resultXmpp.substr(41, 3));
      if(_speedAlarm === 255) {
        setAlarmSpeed(0);
        setIsSensorError(true);
      }
      else setAlarmSpeed(_speedAlarm);

      setAlarmDate(((day, month, minutes)=>{
          if(month ==='00' || day ==='00') return '';
          var result = addMinutes(new Date((new Date()).getFullYear(), parseInt(month)-1, parseInt(day), 0, 0), parseInt(minutes));
          return format(result, "dd-MM-yyyy HH:mm");
        })(
          resultXmpp.substr(44, 2),
          resultXmpp.substr(46, 2),
          resultXmpp.substr(48, 4)));
      setShowLoading(false);
    }
    catch {}
    finally {}
  });

  const doXmppRefresh = (e : CustomEvent<RefresherEventDetail>) => {
    doFetchXmpp(defaultXmppObject);
    e.detail.complete();
  }

  useEffect(() => {
    let int = setInterval(() => {
      setTimer(t => t + 1);
    }, 10000);

    return () => {
      clearInterval(int);
    };
  }, []);

  return (
    <Wrapper loading={showLoading} help={() => setShowHelp(true)}>
      <MyIonRefresher onIonRefresh={doXmppRefresh}></MyIonRefresher>

      <Help isOpen={showHelp} onDidDismiss={e => setShowHelp(false)}>
        <p>{i18strings.help_roller_wind}</p>
      </Help>

      <Page title={i18strings.menu_roller_windsensor} icon={thunderstorm}>
        <IonList>
          <IonItem lines="none">
            <IonLabel>{i18strings.menu_roller_windsensor}:</IonLabel>
            <IonText>{i18strings.menu_roller_windsensor_on}</IonText>
          </IonItem>
          <IonItem lines="none" hidden={!isSensorError}>
            <IonLabel />
            <IonText color='danger'>
              {i18strings.menu_roller_windsensor_undetected} <IonIcon icon={alertCircleOutline} color='danger' size='large' />
            </IonText>
          </IonItem>
          <IonItem>
            <IonLabel>{i18strings.menu_roller_windsensor_speed}:</IonLabel>
            <IonText>{parseNaN2questionMarks(speed, 0)} km/h</IonText>
          </IonItem>
          <IonItem lines="none">
            <IonLabel />
            {isAlarm 
              ? <IonText color='danger'>
                {i18strings.menu_roller_windsensor_alarm} <IonIcon icon={alertCircleOutline} color='danger' size='large' />
                </IonText>
              : <IonText color='dark'>{i18strings.roller_alarm_off}</IonText>}
          </IonItem>
          <IonItem />
          <IonItem lines='none'>
            <IonLabel>{i18strings.menu_roller_windsensor_lastalarm}:</IonLabel>
            <IonText>{alarmDate}</IonText>
          </IonItem>
          <IonItem>
            <IonLabel>{i18strings.menu_roller_windsensor_alarm_speed}:</IonLabel>
            <IonText>{parseNaN2questionMarks(alarmSpeed, 0)} km/h</IonText>
          </IonItem>
        </IonList>
      </Page>
        
      {status.err}
    </Wrapper>
  )
}
export default PageRollerWind;  
