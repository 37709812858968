//@ts-check

import React, { useState, useEffect } from "react";
import { parseBoolean } from '../../functions/fce-math';

import { IonLabel, IonToggle,
         IonCard, IonCardHeader, IonCardSubtitle,
         IonCardContent, IonToast, IonButton, IonList, IonItem
} from '@ionic/react';

/* Theme variables */
import '../../theme/variables.css';


/* Icons */
import { sunny } from "ionicons/icons";

/* XMPP fetch */
import { useDataXMPP } from '../../functions/fetchXMPP';
import { getArrOfNames, useDebug } from "../../functions/fce-string";
import { TIMEOUT_TOAST_SAVED } from "../../App";
import { i18strings } from "../../functions/i18";
import { Help, Page, Wrapper, SortButton } from "../../functions/myReactComponents"
import { useListRooms, useHandleXmpp } from "../../functions/handleXmpp";

const PageHeatingSummer = (props : any) => {
  const [showLoading, setShowLoading] = useState(true);
  const [showHelp, setShowHelp] = useState(false);
  const [showToastSaved, setShowToastSaved] = useState(false);
  const [unsaved, setUnsaved] = useState(0);
  const [unsavedRooms, setUnsavedRooms] = useState(false);
  const [ListRooms, setRooms] = useState(<></>);
  const [RO, setRO] = useState(0);

  const [summer, doFetchXmpp] = useDataXMPP({
      typefce: 'get',
      fce: '/loadSummerMode',
      param: ''
    }, '');

  const [isSummer, setIsSummer] = useState(false);

  useHandleXmpp(summer.isLoading, summer.data, (resultXmpp) => {
    // Logika je otočená: 0 je letní režim = není topná sezóna
    let result = true;  //výchozí stav - není letní režim

    try {
      if(resultXmpp.length === 1) result = parseBoolean(resultXmpp);
    }
    catch {
      result = true;
    }
    finally {
      setIsSummer(!result);
      setUnsaved(0);
      setTimeout(()=>setUnsaved(u => u | 2), 0)
    }
  });

  //----Načtení místností
  const [listRooms, setListRooms] = useState<{ id : number, name : string }[]>([]);
  useListRooms(setListRooms, setShowLoading);

  //----Načtení statusů přiřazení Léto k místnostem
  const [rooms] = useDataXMPP({typefce: 'get', fce: '/letoLoadRooms', param: ''}, '');

  const defaultListSummer = () => [...Array(32)].map((v, i) => {
    return { id: i, checked: false };
  })
  const [listSummer, setListSummer] = useState(defaultListSummer());

  useHandleXmpp(rooms.isLoading, rooms.data, (resultXmpp) => {
    let result : typeof listSummer = [];

    try {
      if(resultXmpp.length > 1) {
        result = getArrOfNames(resultXmpp, 1).map((row, i) => {
          return { id: i, checked: parseBoolean(row)};
        });
        setShowLoading(false);
      }
    }
    catch {
      result = [];
    }
    finally {
      setListSummer(result);
      setTimeout(()=>setUnsavedRooms(false), 0);
    }
  });

  useEffect(() => {
    setRooms(
      <>
        {listRooms.map((item, index) => (
          <IonItem key={item.id}>
            <IonLabel>{item.name}</IonLabel>
            <IonToggle disabled={summer.err !== null} checked={listSummer[item.id].checked} onIonChange={(e)=>handleOnCheckedRoom(e, item.id)}/>
          </IonItem>
        ))}
      </>
    );
  }, [listRooms, listSummer, RO]);

  const handleOnClickOK = () => {
    doFetchXmpp({
      typefce: 'SET',
      fce: '/saveSummerMode',
      param: isSummer ? "0" : "1"
    });

    setShowToastSaved(true);
    setUnsaved(2);
  }

  const handleOnCheckedRoom = (e, id:number) => {
    var v = e.detail.checked;
    const copy = [...listSummer];
    copy[id].checked = v;
    setListSummer(copy);
    setUnsavedRooms(true);
  }

  const handleOnClickOKsummer = () => {
    let p = '';
    listSummer.forEach(row => p += row.checked ? "1" : "0");

    doFetchXmpp({
      typefce: 'SET',
      fce: '/letoSaveRooms',
      param: p
    });

    setShowToastSaved(true);
    setUnsavedRooms(false);
  }

  useDebug(unsaved);
  useDebug(unsavedRooms);

  return (
    <Wrapper loading={showLoading} help={() => setShowHelp(true)}>
      <Help isOpen={showHelp} onDidDismiss={e => setShowHelp(false)}>
        <p>{i18strings.help_heating_summer}</p>
        <ul>
          <li>{i18strings.help_heating_summer1}</li>
          <li>{i18strings.help_heating_summer2}</li>
        </ul>
        <p>{i18strings.help_heating_summer3}</p>
      </Help>

      <IonToast
        isOpen={showToastSaved}
        onDidDismiss={() => setShowToastSaved(false)}
        message={i18strings.action_settings_saved}
        duration={TIMEOUT_TOAST_SAVED}
      />

      <Page title={i18strings.menu_heating_summer} icon={sunny} unsaved={!!(unsaved & 1) || unsavedRooms}>
        <IonCard>
          <IonCardContent>
            <IonList>
              <IonItem>
                <IonLabel>{isSummer ? i18strings.menu_heating_summer_on : i18strings.menu_heating_summer_off}</IonLabel>
                <IonToggle value="summer" disabled={summer.err !== null} checked={isSummer} onIonChange={(e) => {
                  setIsSummer(e.detail.checked);
                  setUnsaved(u => u | (u >> 1));
                }}/>
              </IonItem>
            </IonList>
            <IonButton disabled={summer.err !== null || summer.isLoading || !(unsaved & 1)} expand="block" onClick={handleOnClickOK}>{i18strings.action_save}</IonButton>
          </IonCardContent>
        </IonCard>
        <IonCard>
          <IonCardHeader>
            <IonCardSubtitle>{i18strings.action_save_circuit}</IonCardSubtitle>
          </IonCardHeader>
          <IonCardContent>
            <SortButton listSetter={setListRooms} sortSetter={setRO} />
            <IonButton disabled={summer.err !== null || summer.isLoading || !unsavedRooms} expand="block" onClick={handleOnClickOKsummer}>{i18strings.action_save_circuit}</IonButton>
            <IonList>
              {ListRooms}
            </IonList>
            <IonButton disabled={summer.err !== null || summer.isLoading || !unsavedRooms} expand="block" onClick={handleOnClickOKsummer}>{i18strings.action_save_circuit}</IonButton>
          </IonCardContent>
        </IonCard>
      </Page>

      {summer.err}
    </Wrapper>
  )
}
export default PageHeatingSummer;
