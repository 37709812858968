import { IonPopover } from "@ionic/react";
import React, { FC, PropsWithChildren, useEffect, useRef } from "react";

export const CenteredPopover : FC<PropsWithChildren<{
  isOpen : boolean,
  onDidDismiss : () => void,
}>> = (props) => {
  const ref = useRef<HTMLIonPopoverElement>(null);

  // centrovani kazi hlavne iphone
  useEffect(() => {
    const setter = () => {
      if (!ref.current) return;

      const elem = ref.current.shadowRoot?.querySelector("div.popover-content");
      if (!elem) return;

      elem.setAttribute("style",
        "top: 50% !important;\
        left: 50% !important;\
        transform: translate(-50%, -50%) !important;"
      );
      console.log("setting style");
    }

    setTimeout(setter, 12);
    setTimeout(setter, 25);
    setTimeout(setter, 50);
    setTimeout(setter, 100);
  }, [props.isOpen]);

  return <IonPopover
    isOpen={props.isOpen}
    onDidDismiss={props.onDidDismiss}
    class="info popover"
    keepContentsMounted
    ref={ref}
  >
    {props.children}
  </IonPopover>
}