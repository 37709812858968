import { useMemo } from "react";
import { useFetchRoomsXMPP } from "./fetchRoomsXMPP";
import { useDataXMPP } from "./fetchXMPP";
import { useHandleXmpp } from "./handleXmpp";
import { parseBoolean } from "./fce-math";

function useAbstractRoomList<T>(
  count : '/numOfRooms' | '/numOfRollerShutters',
  detail : '/moreWholeRoom' | '/moreRollerShutter',
  entrySize : number,
  parser : (row : string, i : number) => T
) {
  const [countRooms] = useDataXMPP({typefce: 'get', fce: count, param: '' }, '');
  useHandleXmpp(countRooms.isLoading, countRooms.data, () => fetchRooms(roomQuerry));
  
  const roomQuerry = { typefce: 'get', fce: detail, param: countRooms.data };
  const [roomsXmpp, fetchRooms] = useFetchRoomsXMPP(roomQuerry, [], entrySize);

  const loading = countRooms.isLoading || roomsXmpp.isLoadingXmppListRooms;
  const error = countRooms.err ?? (roomsXmpp.isErrorXmppListRooms ? roomsXmpp.errMsgXmppListRooms : null);
  const res = useMemo(() =>
    loading || (error !== null)
    ? undefined
    : roomsXmpp.dataXmppListRooms!.map(parser),
    [countRooms, roomsXmpp]);

  return {
    loading,
    error,
    entries: res,
    reload: () => fetchRooms({...roomQuerry})
  }
}

export const useFullHeatingRoomList = () =>
  useAbstractRoomList('/numOfRooms', '/moreWholeRoom', 47, (row, id) => ({
    id,
    active: row[0] === '1',
    name: row.slice(1, 14),
    actualTemp: parseFloat(row.slice(14, 9)),
    setTemp: parseFloat(row.slice(19, 22)),
    setTempTotal: parseFloat(row.slice(22, 27)),
    userOfset: parseFloat(row.slice(27, 32)),
    // 32-36?
    isHeating: row[36] === '1',
    isOpenWindow: row[37] === '1',
    isGuestCard: row[38] === '1',
    validity: row.slice(39, 42),
    isLow: row[42] === '1',
    isSummer: row[43] === '1',
    isCooling: row[44] === '1',
    idAirSensor: parseInt(row.slice(45, 47)) || 0
  }))

export const useFullRollerRoomList = () =>
  useAbstractRoomList('/numOfRollerShutters', '/moreRollerShutter', 33, (row, id) => ({
    id,
    use: parseInt(row[0]),
    name: row.slice(1, 14),
    actualPosition: parseInt(row.slice(14, 17), 10) || 0, //uvedeno v %
    setPosition: parseInt(row[17]),
    centralActive: parseBoolean(row[18]),
    manualActive: parseBoolean(row[19]),
    error: parseInt(row[20], 10) || 0,
    validity: parseInt(row.slice(21, 24), 10),
    setRotation: parseInt(row.slice(24, 26), 10),
    manualPosition: parseInt(row.slice(26, 29), 10), //uvedeno v %
    manualRotation: parseInt(row.slice(29, 31), 10),
    actualRotation: parseInt(row.slice(31, 33), 10)
  }))
