//@ts-check

import { IonPage, IonContent, IonList, IonRouterOutlet } from '@ionic/react';
import { options,swapHorizontal, informationCircle, handRight, thunderstorm } from 'ionicons/icons';
import React, { FC, createContext, useState } from 'react';
import './Tabs.css';

import { BwContext, ctxBits } from "../context/GlobalContext"
import { i18strings } from '../functions/i18';
import TabContext, { TabItem, TabHeader } from '../functions/myReactComponents';
import { Route, Redirect } from 'react-router';
import PageRollerInfoRoom from './roller/PageRollerInfoRoom';
import PageRollerCentral from './roller/PageRollerCentral';
import PageRollerManual from './roller/PageRollerManual';
import PageRollerRegimeList from './roller/PageRollerRegimeList';
import PageRollerRegimeSet from './roller/PageRollerRegimeSet';
import PageRollerRoomList from './roller/PageRollerRoomList';
import { useFullRollerRoomList } from '../functions/roomlist';
import PageRollerRoomSet from './roller/PageRollerRoomSet';
import PageRollerWind from './roller/PageRollerWind';
import MassRollerAssign from './roller/MassRollerAssignment';

const PageList: FC<{
  dvcdis: (userInvoked: boolean) => void
}> = (props) => {
  return (
    <IonPage>
      <TabHeader dvcdis={props.dvcdis} title={i18strings.title_tab_roller} />

      <IonContent>
      <BwContext.Consumer>{bc =>
        <IonList lines="none" hidden={!!(bc.bits & ctxBits.loading)}>
          <TabContext.Provider value={["roller", !(bc.bits & ctxBits.xmppOnline)]}>
            <TabItem link="inforoom" title={i18strings.menu_roller_inforoom} icon={informationCircle} />
            <TabItem link="manual" title={i18strings.menu_roller_manualchange} icon={handRight} />
            <TabItem link="central" title={i18strings.menu_roller_central_button} icon={handRight} />
            <TabItem link="regimelist" title={i18strings.menu_roller_programsettings} icon={options} />
            <TabItem link="roomlist" title={i18strings.menu_roller_circuitsetting} icon={swapHorizontal} />
            <TabItem link="massassign" title={i18strings.menu_roller_masscircuitsetting} icon={swapHorizontal} />
          {bc.bits & ctxBits.useWs ?
            <TabItem link="wind" title={i18strings.menu_roller_windsensor} icon={thunderstorm} />:""}
          </TabContext.Provider>
        </IonList>
        }</BwContext.Consumer>
      </IonContent>
    </IonPage>
  );
};

export const rroomctx = createContext<ReturnType<typeof useFullRollerRoomList>>({
  loading: false,
  error: "Room context not provided",
  entries: undefined,
  reload: () => {}
});

const TabRoller: FC<{
  dvcdis: (userInvoked: boolean) => void,
  firmware: number
}> = (tprops) => {
  const [refresh, setRefresh] = useState(false);
  const rooms = useFullRollerRoomList();
  
  return (
  <IonPage>
    <rroomctx.Provider value={rooms}>
      <IonRouterOutlet>
        <Route exact path="/roller" render={() => 
        <BwContext.Consumer>{bits =>
          (bits.bits & (ctxBits.roller | ctxBits.heating)) === ctxBits.heating
          ? <Redirect to="/heating" />
          : <PageList {...tprops} />
        }</BwContext.Consumer>
        } />
      
        <Route exact path="/roller/inforoom" component={PageRollerInfoRoom} />
        <Route exact path="/roller/central" component={PageRollerCentral} />
        <Route exact path="/roller/manual" component={PageRollerManual} />
        <Route exact path="/roller/regimelist" render={() => <PageRollerRegimeList refresh={refresh}/>} />
        <Route path="/roller/regimelist/regime/:id/:name" render={props =>
          <PageRollerRegimeSet {...props} setRefresh={setRefresh} refresh={refresh} firmware={tprops.firmware} />} />
        <Route exact path="/roller/roomlist" component={PageRollerRoomList} />
        <Route path="/roller/roomlist/room/:id/:name" component={PageRollerRoomSet} />
        <Route exact path="/roller/wind" component={PageRollerWind} />
        <Route exact path="/roller/massassign" component={MassRollerAssign} />
      </IonRouterOutlet>
    </rroomctx.Provider>
  </IonPage>
  );
};

export default TabRoller;
