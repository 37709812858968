//@ts-check

import React, { useState } from "react";
import { RefresherEventDetail } from '@ionic/core';
import { getArrOfNames } from '../../functions/fce-string';

import { IonLabel, IonList, IonItem } from '@ionic/react';

/* Theme variables */
import '../../theme/variables.css';


/* Icons */
import { options } from "ionicons/icons";

import { useDataXMPP } from '../../functions/fetchXMPP';
import { i18strings } from "../../functions/i18";
import { MyIonRefresher, Page, Wrapper } from "../../functions/myReactComponents";
import { useHandleXmpp } from "../../functions/handleXmpp";

const PageRollerRegimeList = (props : any) => {
  const [showLoading, setShowLoading] = useState(true);

  const defaultXmppObject = {typefce: 'get', fce: '/listOfPrograms', param: ''}
  const [progs, doFetchXmpp] = useDataXMPP(defaultXmppObject, '', props.refresh);  

  const [listRegimes, setListRegimes] = useState<{ id : number, name : string }[]>([]);
  
  useHandleXmpp(progs.isLoading, progs.data, (resultXmpp) => {
    let result : typeof listRegimes = [];

    try {  
      result = getArrOfNames(resultXmpp, 13).map((row, i) => {
        return { id: i, name: row };
      });
    }
    catch(e) {
      console.error(e);
      result = [];
    }
    finally {
      setListRegimes(result);
      setShowLoading(false);
    }
  });
  
  const doXmppRefresh = (e : CustomEvent<RefresherEventDetail>) => {
    doFetchXmpp(defaultXmppObject);
    e.detail.complete();
  }

  const ListRegimes = () => {
    return (
      <>
        {listRegimes.map((item, index) => (
          <IonItem key={item.id} routerLink={'/roller/regimelist/regime/' + index.toString() +'/' + item.name}>
            <IonLabel>{item.name}</IonLabel>
          </IonItem>
        ))}
      </>
    );
  }

  return (
    <Wrapper loading={showLoading}>
      <MyIonRefresher onIonRefresh={doXmppRefresh}></MyIonRefresher>

      <Page title={i18strings.menu_roller_programsettings} icon={options}>
        <IonList>
          <ListRegimes/>
        </IonList>
      </Page>

      {progs.err}
    </Wrapper>
  )
}
export default PageRollerRegimeList;
