//@ts-check

import React, { useState, useEffect } from "react";
import { HC64dateTimeFormat2ISO,
         ISOdateTimeFormat2HC64, prepareSaveTemp, getArrOfNames, TimezonelessNow } from '../../functions/fce-string';
import { MyIonDateTime, Help, Page, Wrapper, ErrToast, SortButton } from '../../functions/myReactComponents';

import { IonLabel, IonToggle,
         IonCard, IonCardHeader, IonCardSubtitle,
         IonCardContent, IonCardTitle, IonRange,
         IonIcon, IonText,
         IonToast, IonButton, IonList, IonItem, IonAlert
} from '@ionic/react';

/* Theme variables */
import '../../theme/variables.css';


/* Icons */
import { thermometer, moon } from "ionicons/icons";
import { useDataXMPP } from '../../functions/fetchXMPP';
import { parseBoolean } from "../../functions/fce-math";
import { TIMEOUT_TOAST_SAVED } from "../../App";
import { i18strings } from "../../functions/i18";
import { useHandleXmpp, useListRooms } from "../../functions/handleXmpp";

const PageHeatingLow = (props : any) => {
  const [showLoading, setShowLoading] = useState(true);
  const [showHelp, setShowHelp] = useState(false);
  const [showToastSaved, setShowToastSaved] = useState(false);
  const [unsaved, setUnsaved] = useState(0);
  const [unsavedRooms, setUnsavedRooms] = useState(0);

  const [lowXmpp, doFetchXmpp] = useDataXMPP({typefce: 'get', fce: '/loadLows', param: ''}, '');

  const [low, setLow] = useState<boolean | null>(null);
  const [valueRange, setValueRange] = useState(0);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [disabledEndDate, setDisableEndDate] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [ListRooms, setRooms] = useState(<></>);
  const [RO, setRO] = useState(0);

  useHandleXmpp(lowXmpp.isLoading, lowXmpp.data, (resultXmpp) => {
    resultXmpp = resultXmpp.trim();
    setLow(false);
    try {
      let lowtemp = parseFloat(resultXmpp.substr(0, 3));
      if (isNaN(lowtemp)) return;
      switch(resultXmpp.length) {
        // @ts-expect-error fallthrough je umyslny
        case 33: // Je útlum s ukončením, vrací se i datum konce
          setDisableEndDate(false);
          setEndDate(HC64dateTimeFormat2ISO(resultXmpp.substr(18, 15)));
        // @ts-expect-error fallthrough je umyslny
        case 18: // Je útlum trvalý, vrací se i datum startu útlumu
          setLow(true);
          setStartDate(HC64dateTimeFormat2ISO(resultXmpp.substr(3, 15)));
        case  3: // Není útlum, vrací se pouze teplota
          setValueRange(lowtemp);
      }
    }
    catch {}
    finally {
      setUnsaved(0);
    }
  });

  useEffect(()=>{setTimeout(()=>setUnsaved(u => u | 2), 0)}, [valueRange]);

  //----Načtení místností
  const [listRooms, setListRooms] = useState<{name: string, id: number}[]>([]);
  const [isErrorXmpp2, errMsgXmpp2] = useListRooms(setListRooms, setShowLoading);

  //----Načtení statusů přiřazení Léto k místnostem
  const [{ data: dataXmpp3, isLoading: isLoadingXmpp3}] = useDataXMPP(
    {
      typefce: 'get',
      fce: '/lowLoadRooms',
      param: ''
    },
    '',
  );

  const [listLow, setListLow] = useState(() => [...Array(32)].map((v, i) => {return {id: i, checked: false}}));

  useHandleXmpp(isLoadingXmpp3, dataXmpp3, (resultXmpp) => {
    let result: {id: number, checked: boolean}[] = [];
    try {
      if(resultXmpp.length > 1) {
        result = getArrOfNames(resultXmpp, 1)?.map((row, i) => {return {id: i, checked: parseBoolean(row)}}) ?? [];
        setShowLoading(false);
      }
    }
    catch {
      result = [];
    }
    finally {
      setListLow(result);
      setUnsavedRooms(0);
    }
  });

  useEffect(()=>{setTimeout(()=>setUnsavedRooms(u => u | 2), 0)}, [listLow]);

  useEffect(() => {
    setRooms(<>
      {listRooms.map((item, index) => (
        <IonItem key={item.id}>
          <IonLabel>{item.name}</IonLabel>
          <IonToggle disabled={lowXmpp.err !== null} checked={listLow[item.id].checked} onIonChange={(e)=>handleOnCheckedRoom(e, item.id)}/>
        </IonItem>
      ))}
    </>);
  }, [RO, listRooms, listLow]);

  const handleOnSetRange = (e) => {
    setValueRange(parseInt(e.detail.value));
    setUnsaved(u => u | (u >> 1));
  }

  const handleOnChecked = (e) => {
    if (low === null) return;
    setLow(e.target.checked);

    if(e.target.checked)
    {
      setStartDate(TimezonelessNow());
      setShowAlert(true);
    } else {
      setStartDate('');
      setEndDate('');
      setDisableEndDate(true);
    }
    setUnsaved(u => u | (u >> 1));
  }

  const handleOnChangeStartDate = (e) => {
    setStartDate(e.detail.value);
    setUnsaved(u => u | (u >> 1));
  }

  const handleOnChangeMyIonDateTime = (e) => {
    setEndDate(e.detail.value);
    setUnsaved(u => u | (u >> 1));
  }

  const handleOnClickOK = () => {
    let p = prepareSaveTemp(valueRange);

    if (low)
    {
      if(endDate === '') {
        //trvalý útlum
        let date1 = ISOdateTimeFormat2HC64(startDate);
        p += date1;
      }
      else {
        //časový útlum s koncem
        let date1 = ISOdateTimeFormat2HC64(startDate);
        let date2 = ISOdateTimeFormat2HC64(endDate);
        p += date1;
        p += date2;
      }
    }

    doFetchXmpp({typefce: 'SET', fce: '/lowSave', param: p.padEnd(33,'+')});

    setShowToastSaved(true);
    setUnsaved(2);
  }

  const handleOnCheckedRoom = (e, id:number) => {
    var v = e.detail.checked;
    const copy = [...listLow];
    copy[id].checked = v;
    setListLow(copy);
    setUnsavedRooms(u => u | (u >> 1));
  }

  const handleOnClickOKroomsAssign = () => {
    let p = '';
    listLow.forEach(row=>{
      p += row.checked ? "1" : "0";
    })

    doFetchXmpp({
      typefce: 'SET',
      fce: '/lowSaveRooms',
      param: p
    });

    setShowToastSaved(true);
    setUnsavedRooms(2);
  }

  return (
    <Wrapper loading={showLoading} help={() => setShowHelp(true)}>
      <Help isOpen={showHelp} onDidDismiss={() => setShowHelp(false)}>
        <p>{i18strings.help_heating_low}</p>
      </Help>

      <IonToast
        isOpen={showToastSaved}
        onDidDismiss={() => setShowToastSaved(false)}
        message={i18strings.action_settings_saved}
        duration={TIMEOUT_TOAST_SAVED}
      />

      <Page title={i18strings.menu_heating_low} icon={moon} unsaved={!!(unsaved & 1) || !!(unsavedRooms & 1)}>
        <IonCard>
          <IonCardContent>
            <IonList>
              <IonItem>
                <IonLabel>{low ? i18strings.low_activate : i18strings.low_deactivate}</IonLabel>
                <IonToggle value="low" checked={low ?? undefined} onIonChange={handleOnChecked}/>
              </IonItem>
              <IonItem lines="none">
                <IonLabel>{i18strings.temperature_low}:</IonLabel>
                <IonText>{valueRange}°C</IonText>
              </IonItem>
              <IonItem>
                <IonRange max={50} value={valueRange} onIonChange={handleOnSetRange} disabled={!low}>
                  <IonIcon size="small" slot="start" icon={thermometer}/>
                  <IonIcon slot="end" icon={thermometer}/>
                </IonRange>
              </IonItem>
              <IonItem hidden={!low}>
                <IonLabel>{i18strings.low_datetimeLowStart}:</IonLabel>
                <MyIonDateTime min="2020" value={startDate} onIonChange={handleOnChangeStartDate} disabled />
              </IonItem>
              <IonItem hidden={disabledEndDate || !low}>
                <IonLabel>{i18strings.low_datetimeLowStop}:</IonLabel>
                <MyIonDateTime min={startDate} value={endDate} onIonChange={handleOnChangeMyIonDateTime} />
              </IonItem>
            </IonList>
            <IonButton disabled={lowXmpp.err !== null || lowXmpp.isLoading || !(unsaved & 1)} expand="block" onClick={handleOnClickOK}>{i18strings.action_save}</IonButton>
          </IonCardContent>
        </IonCard>

        <IonCard>
          <IonCardHeader>
            <IonCardTitle />
            <IonCardSubtitle>{i18strings.low_setlow}</IonCardSubtitle>
          </IonCardHeader>
          <IonCardContent>
            <SortButton listSetter={setListRooms} sortSetter={setRO} />
            <IonButton disabled={lowXmpp.err !== null || lowXmpp.isLoading || !(unsavedRooms & 1)} expand="block" onClick={handleOnClickOKroomsAssign}>{i18strings.action_save_circuit}</IonButton>
            <IonList>
              {ListRooms}
            </IonList>
            <IonButton disabled={lowXmpp.err !== null || lowXmpp.isLoading || !(unsavedRooms & 1)} expand="block" onClick={handleOnClickOKroomsAssign}>{i18strings.action_save_circuit}</IonButton>
          </IonCardContent>
        </IonCard>
      </Page>

      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        header={i18strings.low_confirm_end}
        message={i18strings.low_confirm_end_tooltip}
        buttons={[
          {
            text: i18strings.button_no,
            role: 'cancel',
            cssClass: 'secondary',
            handler: no => {
              setStartDate(TimezonelessNow());
              setEndDate('');
              setDisableEndDate(true);
            }
          },
          {
            text: i18strings.button_yes,
            handler: () => {
              setEndDate(TimezonelessNow(7));
              setDisableEndDate(false);
            }
          }
        ]}
      />

      {lowXmpp.err}
      <ErrToast isOpen={isErrorXmpp2} message={errMsgXmpp2} />
    </Wrapper>
  )
}
export default PageHeatingLow;
