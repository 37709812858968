import '../theme/variables.css';
import React, { useState, Fragment, useContext } from "react";
import thermometerfloor from "../img/thermometer-floor.svg"
import { IonList, IonItem, IonLabel, IonIcon, IonCheckbox } from "@ionic/react";
import { rroomctx } from '../pages/TabRoller';
import { hroomctx } from '../pages/TabHeating';

function HeatingLabels(rooms : { id : number, name : string, idAirSensor : number }[]) {
  return rooms.map((room) => <Fragment key={room.id}>
    {room.idAirSensor > 0 ? <>&nbsp;<IonIcon icon={thermometerfloor} size="small" /></> : ""}
    <IonLabel>{room.name}</IonLabel>
  </Fragment>)
}

function RollerLabels(rooms : { id : number, name : string }[]) {
  return rooms.map((room) => <IonLabel>{room.name}</IonLabel>)
}

function setBit(bit : number, value : boolean) {
  if (value) return (arr : number) => arr | (1 << bit);
  else return (arr : number) => arr &~ (1 << bit);
}

function getBit(arr : number, bit : number) {
  return (arr & (1 << bit)) !== 0;
}

function * iterateBits(arr : number) {
  for (let exp = 0; exp < 32; exp++) {
    if (getBit(arr, exp)) yield exp;
  }
}


export function useMultiRoomHeatingSelection() {
  const [selected, setSelected] = useState(0);
  const rooms = useContext(hroomctx);

  // TODO: sorting by name/id

  return {
    selected: () => iterateBits(selected),
    state: selected,
    setState: (val : number) => setSelected(val),
    node: rooms.entries && <>
      <IonList>{HeatingLabels(rooms.entries).map((label, id) =>
        <IonItem key={id}>
          {label}
          <IonCheckbox
            checked={getBit(selected, id)}
            onIonChange={ev => setSelected(setBit(id, ev.detail.checked))}
          />
        </IonItem>
      )}</IonList>
    </>
  }
}

export function useMultiRoomRollerSelection() {
  const [selected, setSelected] = useState(0);
  const rooms = useContext(rroomctx);

  return {
    selected: () => iterateBits(selected),
    state: selected,
    setState: setSelected,
    node: rooms.entries && <>
      <IonList>{RollerLabels(rooms.entries).map((label, id) =>
        <IonItem key={id}>
          {label}
          <IonCheckbox
            checked={getBit(selected, id)}
            onIonChange={ev => setSelected(setBit(id, ev.detail.checked))}
          />
        </IonItem>
      )}</IonList>
    </>
  }
}