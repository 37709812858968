//@ts-check

import React, { useState, useEffect } from "react";
import { RefresherEventDetail } from '@ionic/core';
import { compareTime, replaceDiacritic, prepareID, prepareSaveTemp, addMinutes } from '../../functions/fce-string';
import { IonDateTimeRegime, IonRangeRegime, MyIonRefresher, Help, Page, Wrapper } from '../../functions/myReactComponents';

import { IonLabel, IonIcon,
         IonToast, IonButton, IonList, IonItem,
         IonInput, IonFab, IonFabButton, IonBadge, IonAlert, IonPopover, IonPicker
} from '@ionic/react';

/* Theme variables */
import '../../theme/variables.css';

/* Icons */
import { add, remove, options, pencil } from "ionicons/icons";
import { useDataXMPP } from '../../functions/fetchXMPP';
import { TIMEOUT_TOAST_SAVED } from "../../App";
import { i18strings } from "../../functions/i18";
import { useHandleXmpp } from "../../functions/handleXmpp";
import sampleRegimes from "../../functions/sampleRegimes";

const PageHeatingRegimeSet = ({match, setRefresh, refresh}) => {
  const [showLoading, setShowLoading] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [showHelp, setShowHelp] = useState(false);
  const [showSamples, setShowSamples] = useState(false);
  const [showToastSaved, setShowToastSaved] = useState(false);
  const [unsaved, setUnsaved] = useState(false);
  const [tempPick, callTempPick] = useState([-1, 0]);

  const defaultXmppObject = {
    typefce: 'get',
    fce: '/loadMode',
    param: prepareID(parseInt(match.params.id))
  }

  const [mode, doFetchXmpp] = useDataXMPP(defaultXmppObject, '');

  const defaultArrRegimes = () => {let a = [...Array(8)].map(()=>{return{
    time: '00:00',
    temp: 0,
    disabled: true,
    }});
    return a;
  }
  const [regimeName, setRegimeName] = useState('');
  const [regimes, setRegimes] = useState(defaultArrRegimes());
  const [blockHandle, setBlockHandle] = useState(true);

  const loadRegime = (regstr: string) => {
    let regarr = (regstr.match(/[0-9]{2}:[0-9]{2}[0-9\-][0-9]{2}/g) ?? []).map(row => {
      let rawTemp = parseInt(row.substr(5, 3));
      return {
        time: row.substr(0, 5),
        temp: rawTemp
      }
    });
    let clone = defaultArrRegimes();
    regarr.forEach((row, i) => {
      clone[i].temp = row.temp;
      clone[i].time = row.time;
      clone[i].disabled = false;
    });
    clone[0].disabled = true;
    setBlockHandle(true);
    setTimeout(() => setRegimes(clone), 0);
  }

  useHandleXmpp(mode.isLoading, mode.data, (resultXmpp) => {
    let resultRegimeName = '';

    try {
      if(resultXmpp.length >= 13) {
        resultRegimeName = resultXmpp.substr(0, 13).trim();
        loadRegime(resultXmpp.substr(13, resultXmpp.length))
        setShowLoading(false);
      }
    } catch {
      resultRegimeName = '';
      setRegimes(defaultArrRegimes());
    } finally {
      setRegimeName(resultRegimeName);
    }
  });

  useEffect(()=>{
    setTimeout(()=>
    setBlockHandle(false), 0);
  }, [regimes]);

  const doXmppRefresh = (e : CustomEvent<RefresherEventDetail>) => {
    doFetchXmpp(defaultXmppObject);
    e.detail.complete();
    setUnsaved(false);
  }

  const handleOnChangeInput = (e) => {
    setRegimeName(replaceDiacritic(e.detail.value));
    if (blockHandle) return;
    setUnsaved(true);
  }

  const handleOnChangeStartTime = (time: string, idRegime : number) => {
    if(blockHandle) return;
    const copy = [...regimes];
    if(time !== '00:00')
      copy[idRegime].time = compareTime(time, copy[idRegime-1].time);
    setRegimes(copy);
    setUnsaved(true);
  }

  const handleOnSetRange = (e, idRegime : number) => {
    const copy = [...regimes];
    copy[idRegime].temp = parseInt(e.detail.value);
    setRegimes(copy);
    if(blockHandle) return;
    setUnsaved(true);
  }

  const handleOnClickAdd = () => {
    const clone = [...regimes];
    for(let i = 1; i < 8; i++) {
      if(clone[i].disabled)
      {
        clone[i].disabled = false;
        clone[i].temp = 20;
        clone[i].time = addMinutes(clone[i-1].time, 5);
        break;
      }
    }
    setRegimes(clone);
    setUnsaved(true);
  }

  const handleOnClickRemove = () => {
    const clone = [...regimes];
    for(let i = 7; i > 0; i--) {
      if ( !clone[i].disabled ) {
        clone[i].disabled = true;
        clone[i].temp = 0;
        clone[i].time = '00:00';
        break;
      }
    }
    setRegimes(clone);
    setUnsaved(true);
  }

  const handleOnClickOK = () => {
    //!!! Místo : se musí použít '%3A'
    let p = prepareID(parseInt(match.params.id));
    p += regimeName.padEnd(13,'+');

    regimes.forEach((r, i) => { if (r.time !== '00:00' || !i)
      p += r.time.replace(":", "%3A") + prepareSaveTemp(r.temp);
    });
    console.log(p);

    const saveXmppObject = {
      typefce: 'SET',
      fce: '/saveMode',
      param: p
    }
    doFetchXmpp(saveXmppObject);

    setShowToastSaved(true);
    setTimeout(()=>setRefresh(!refresh), 0);
    setUnsaved(false);
  }

  return (
    <Wrapper loading={showLoading} help={() => setShowHelp(true)}>
      <MyIonRefresher onIonRefresh={doXmppRefresh} />

      <Help isOpen={showHelp} onDidDismiss={e => setShowHelp(false)}>
        <p>{i18strings.help_heating_regimeset}</p>
      </Help>

      <IonToast
        isOpen={showToastSaved}
        onDidDismiss={() => setShowToastSaved(false)}
        message={i18strings.action_settings_saved}
        duration={TIMEOUT_TOAST_SAVED}
      />

      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        header={i18strings.warning}
        message={i18strings.menu_heating_regimedelete}
        buttons={[
          {
            text: i18strings.button_no,
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {}
          },
          {
            text: i18strings.button_yes,
            handler: () => {
              setRegimes(defaultArrRegimes());
              doFetchXmpp({typefce: 'SET', fce: '/deleteMode', param: prepareID(parseInt(match.params.id))});
            }
          }
        ]}
      />

      <IonPopover class="popover"
        isOpen={showSamples}
        onDidDismiss={() => setShowSamples(false)} >
        <IonList>
          {sampleRegimes.map((v, i) => {return (
            <IonItem key={i} onClick={()=>{loadRegime(v.reg); setShowSamples(false); setUnsaved(true);}}>
              {v.name}
            </IonItem>
          )})}
        </IonList>
      </IonPopover>

      <Page title={i18strings.menu_heating_regimeset} icon={options} unsaved={unsaved}>
        <IonItem color='primary'>
          <IonIcon icon={pencil} size="small" />&nbsp;&nbsp;
          <IonInput type='text' maxlength={13} value={regimeName} onIonChange={handleOnChangeInput} />
        </IonItem>
        <IonButton color="light" onClick={() => setShowSamples(true)}>
          {i18strings.heating_samples}
        </IonButton>
        <IonButton disabled={mode.err !== null || mode.isLoading || !unsaved} expand="block" onClick={handleOnClickOK}>{i18strings.action_save}</IonButton>
        <IonList>
          {regimes.map((v, i, a) => 
            v.disabled && i ? "" :
            <IonItem key={i} className="compactitem">
              <IonBadge>{i + 1}</IonBadge>
              <IonDateTimeRegime value={v.time} onIonChange={time=>handleOnChangeStartTime(time, i)} disabled={!i}
                min={i ? addMinutes(a[i - 1].time) : "00:00"}
                max={i + 1 < a.length ? addMinutes(a[i + 1].time, -1) : "23:55"} />
              <IonRangeRegime value={v.temp} onIonChange={e=>handleOnSetRange(e, i)} min={-5} />
              <IonItem onClick={()=>callTempPick([i, v.temp])} button>
                <IonLabel>{v.temp} °C </IonLabel>
              </IonItem>
            </IonItem>
          )}
        </IonList>

        <IonFab vertical="top" horizontal="end" edge slot="fixed" >
          <IonFabButton color="primary" size='small' onClick={handleOnClickAdd} disabled={!regimes[regimes.length - 1].disabled}>
            <IonIcon icon={add} />
          </IonFabButton>
          <IonFabButton color="danger" size='small' onClick={handleOnClickRemove} disabled={regimes[1].disabled}>
            <IonIcon icon={remove} />
          </IonFabButton>
        </IonFab>

        <IonButton disabled={mode.err !== null || mode.isLoading || !unsaved} expand="block" onClick={handleOnClickOK}>{i18strings.action_save}</IonButton>
        <IonLabel>&nbsp;</IonLabel>
        <IonButton disabled={mode.err !== null || mode.isLoading} expand="block" onClick={()=>setShowAlert(true)}>{i18strings.button_delete_settings}</IonButton>
        <IonPicker
          columns={[
            {
              name: "Temperature",
              options:[...Array(86)].map((_,i)=>{return {text: (i-5).toString() + " ºC", value: i-5};}),
              selectedIndex: tempPick[1]+5
            }]}
          isOpen={tempPick[0] >= 0}
          onDidDismiss={()=>callTempPick([-1, 0])}
          buttons={[
            {
              text: "OK",
              handler: (v)=>{
                let _reg = regimes;
                _reg[tempPick[0]].temp = v.Temperature.value;
                setRegimes(_reg);
                callTempPick([-1, 0]);
                return;
              }
            }
          ]}
          />
      </Page>

      {mode.err}
    </Wrapper>
  )
}
export default PageHeatingRegimeSet;
