//@ts-check

import React, { Fragment, useContext, useState } from "react";
import { getArrOfNames } from '../../functions/fce-string';

import { IonLabel, IonToast, IonButton, IonItem, 
         IonSlides, IonSlide, IonAlert, IonCardHeader, IonCard, IonChip, IonIcon } from '@ionic/react';

/* Theme variables */
import '../../theme/variables.css';


/* Icons */
import { optionsOutline, swapHorizontal } from "ionicons/icons";
import { RouteComponentProps } from "react-router";
import { useDataXMPP } from '../../functions/fetchXMPP';
import { prepareID } from "../../functions/fce-string";
import { TIMEOUT_TOAST_SAVED, TIMEOUT_TOAST_ERROR, sendXMPPmessage } from "../../App";
import { i18strings } from "../../functions/i18";
import { Page, Wrapper, Help, RegimesAssignment } from "../../functions/myReactComponents";
import { useHandleXmpp } from "../../functions/handleXmpp";
import { SelectedDeviceContext } from "../../context/GlobalContext";
import { randomID } from "../../functions/fce-math";

interface UserDetailPageProps extends RouteComponentProps<{
  id: string;
  name: string;
}> {}
  
const PageCoolingRoomSet : React.FunctionComponent <UserDetailPageProps> = ({match}) => {
  const [showLoading, setShowLoading] = useState(true);
  const [showHelp, setShowHelp] = useState(false);
  const [showToastSaved, setShowToastSaved] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [isError, setIsError] = useState(false);
  const [unsaved, setUnsaved] = useState(false);

  const [modeList] = useDataXMPP({typefce: 'get', fce: '/listOfModes', param: ''}, '');  
  const [roomSettings] = useDataXMPP(
    {typefce: 'get', fce: '/roomSettingsCool', param: prepareID(parseInt(match.params.id))}, '');

  const [listRegimes, setListRegimes] = useState<{ id: number, name: string }[]>([]);
  
  const dvc = useContext(SelectedDeviceContext);

  const defaultRegimes = [...Array(7)].map((v,i) => {
    return {
      id: i,
      value: -1,
      isFirstDay: false
    }
  });
  const [regimes, setRegimes] = useState(defaultRegimes);
  
  useHandleXmpp(modeList.isLoading, modeList.data , (data) => {
    let result : typeof listRegimes = [];
    try {  
      if(data.length >= 13)
        result = getArrOfNames(data, 13).map((row, i) => { return { id: i, name: row } });
    }
    catch {
      result = [];
    }
    finally {
      setListRegimes(result)
    }
  });

  useHandleXmpp(roomSettings.isLoading, roomSettings.data, (data) => {
    if(data.length > 2 ) {
      let _rInt = getArrOfNames(data.slice(0),2).map(v => parseInt(v));

      const clone = [...defaultRegimes];
      clone.forEach((v, i) => {
        let a: number = _rInt[i];
        v.value = a % 32;
        v.isFirstDay = a >= 32;
      })
      setRegimes(clone);
      setShowLoading(false);
      setTimeout(()=>setUnsaved(false), 0);
    }
  });

  const slideOpts = {
    initialSlide: 0,
    pager: true,
    stretch: 0,
    speed: 0,
    freemode: true
  };

  const handleOnSelectItem = (e, id:number) => {
    const copy = [...regimes];
    copy[id].value = parseInt(e.detail.value);
    setRegimes(copy);
    setUnsaved(true);
  }

  const handleOnClickOK = async () => {
    //• ID místnosti (2 znaky)
    //• id režimů jednotlivých dnů (každý režim 2 znaky).  '-1' značí nepřiřazený režim a je doplněn do 21 dnů. 

    let reg = '';
    for(let i = 0; i < Object.keys(regimes).length; i++) {
      reg += prepareID(regimes[i].value);
    };

    {
      let ctrl = reg.split("-");
      ctrl.splice(0, 1);
      if(ctrl.some((v)=>{return v.length !== 1})){
        setIsError(true);
        return;
      }
    }

    setUnsaved(false);

    await sendXMPPmessage(
      dvc,
      randomID(),
      'SET',
      '/saveAssignmentModesCool',
      prepareID(parseInt(match.params.id)) + reg
    );

    setShowToastSaved(true);
  }

  return (
    <Wrapper loading={showLoading} help={() => setShowHelp(true)}>

      <Help isOpen={showHelp} onDidDismiss={e => setShowHelp(false)}>
        <p>{i18strings.help_cooling_roomset}</p>
        <p>{i18strings.help_regime_assignment.split("{ic}").map((v, i, a) => <Fragment key={i}>
          {v}
          {i < a.length - 1 && <IonIcon icon={optionsOutline} style={{fontSize: "1.5em", marginBottom: "-5px"}} />}
        </Fragment>)}</p>
      </Help>

      <IonToast
        isOpen={showToastSaved}
        onDidDismiss={() => setShowToastSaved(false)}
        message={i18strings.action_settings_saved}
        duration={TIMEOUT_TOAST_SAVED}
      />

      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        header = {i18strings.warning}
        message = {i18strings.confirm_delete_settings}
        buttons = {[
          {
            text: i18strings.button_no,
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {}
          },
          {
            text: i18strings.button_yes,
            handler: () => setRegimes(defaultRegimes)
          }
          ]}
      />

      <Page title={i18strings.menu_cooling_circuitsetting} icon={swapHorizontal} unsaved={unsaved}>
        <IonItem color='primary'>
          {match.params.name}
        </IonItem>

        <IonButton expand="block" disabled={roomSettings.err !== null || !unsaved} onClick={handleOnClickOK}>{i18strings.action_save}</IonButton>

        <IonSlides pager={true} options={slideOpts}>
          <IonSlide>
            <IonCard>
              <IonCardHeader>
                <IonChip><IonLabel> {i18strings.menu_cooling_circuitsetting} </IonLabel></IonChip>
              </IonCardHeader>
                <RegimesAssignment
                  regimeAssignments={regimes}
                  defaultRegime={-1}
                  loc="/heating/regimelist"
                  handleSelect={handleOnSelectItem}
                  possibleRegimes={listRegimes}
                />
            </IonCard>
          </IonSlide>
        </IonSlides>

        <IonButton expand="block" disabled={roomSettings.err !== null || !unsaved} onClick={handleOnClickOK}>{i18strings.action_save}</IonButton>
        <IonLabel>&nbsp;</IonLabel>
        <IonButton expand="block" disabled={roomSettings.err !== null} onClick={()=>setShowAlert(true)}>{i18strings.button_delete_settings}</IonButton>      
      </Page>

      {modeList.err}
      {roomSettings.err}
      <IonToast
        isOpen={isError}
        onDidDismiss={() => setIsError(false)}
        message = {i18strings.menu_heating_previousregime_warning}
        position="bottom"
        duration={TIMEOUT_TOAST_ERROR * 2}
        buttons={[{text: 'OK', role: 'cancel'}]}
      />
    </Wrapper>
    )
  }
  export default PageCoolingRoomSet;  
