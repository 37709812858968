//@ts-check

import React, { useState } from "react";
import { RefresherEventDetail } from '@ionic/core';
import {  } from '../../functions/fce-string';

import { IonLabel, IonText, IonList, IonItem } from '@ionic/react';

/* Theme variables */
import '../../theme/variables.css';

/* Icons */
import { informationCircle } from "ionicons/icons";

import { useDataXMPP } from '../../functions/fetchXMPP';
import { i18strings } from "../../functions/i18";
import { MyIonRefresher, Page, Wrapper } from "../../functions/myReactComponents";
import { useHandleXmpp } from "../../functions/handleXmpp";

const PageSettingInfo = (props : any) => {
  const [showLoading, setShowLoading] = useState(true);

  const defaultXmppObject = {
    typefce: 'get',
    fce: '/version',
    param: ''
  }
  const [vers, doFetchXmpp] = useDataXMPP(
    defaultXmppObject,
    'nezjištěno',
  );

  const [version, setVersion] = useState('');

  useHandleXmpp(vers.isLoading, vers.data, (resultXmpp) => { 
    let result = i18strings.menu_setting_fw_undetected;  

    try {
      if(resultXmpp.length === 2) {
        result = parseInt(resultXmpp[0], 16) + '.' + parseInt(resultXmpp[1], 16);  
        setShowLoading(false);
      }
    }
    catch {
      result = i18strings.menu_setting_fw_undetected
    }
    finally {
      setVersion(result);
    }
  });

  const doXmppRefresh = (e : CustomEvent<RefresherEventDetail>) => {
    doFetchXmpp(defaultXmppObject);
    e.detail.complete();
  }

  return (
    <Wrapper loading={showLoading}>
      <Page title={i18strings.menu_setting_fw} icon={informationCircle}>
        <IonList>
          <IonItem lines="none">
            <IonLabel>{i18strings.menu_setting_fw}: </IonLabel><IonText>{version}</IonText>
          </IonItem>
        </IonList>
      </Page>

      <MyIonRefresher onIonRefresh={doXmppRefresh} />
      {vers.err}
    </Wrapper>
  )
}
export default PageSettingInfo;  
