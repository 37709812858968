//@ts-check

import { useState, useReducer, useLayoutEffect, useContext } from "react";

import { sendXMPPmessage, deleteAllRecordFromBuffers } from '../App';
import { randomID } from './fce-math';
import { prepareID, getArrOfNames } from "./fce-string";
import { i18strings } from "./i18";
import { SelectedDeviceContext } from "../context/GlobalContext";

type loaderState = {
    isLoadingXmppListRooms : true,
    isErrorXmppListRooms : false,
    errMsgXmppListRooms : ' ',
    dataXmppListRooms ?: string[]
  } | {
    isLoadingXmppListRooms : false,
    isErrorXmppListRooms : false,
    errMsgXmppListRooms : ' ',
    dataXmppListRooms : string[]
  } | {
    isLoadingXmppListRooms : false,
    isErrorXmppListRooms : true,
    errMsgXmppListRooms : unknown,
    dataXmppListRooms ?: string[]
  }

/*
--- XMPP Reducer---
*/
const dataXmppFetchReducer = (
  state : loaderState,
  action : {
    type : "FETCH_INIT"
  } | {
    type : "FETCH_FAILURE",
    payload : unknown
  } | {
    type : "FETCH_SUCCESS",
    payload : string[]
  }
) => {
  switch (action.type) {
    case 'FETCH_INIT':
      return {
        ...state,
        isLoadingXmppListRooms: true,
        isErrorXmppListRooms: false,
        errMsgXmppListRooms: ' ',
      } as loaderState;
    case 'FETCH_SUCCESS':
      return {
        ...state,
        isLoadingXmppListRooms: false,
        isErrorXmppListRooms: false,
        errMsgXmppListRooms: ' ',
        dataXmppListRooms: action.payload
      } as loaderState;
    case 'FETCH_FAILURE':
      return {
        ...state,
        isLoadingXmppListRooms: false,
        isErrorXmppListRooms: true,
        errMsgXmppListRooms: action.payload,
      } as loaderState;
    default:
      throw new Error();
  }
};

export function useFetchRoomsXMPP(
  initialXMPPparam : {
    typefce : string,
    fce : string,
    param : string,
  },
  initialData : string[],
  roomLength : number
) {
  const [xmppParam, setXmppParam] = useState(initialXMPPparam);
  const [state, dispatch] = useReducer(dataXmppFetchReducer, {
    isLoadingXmppListRooms: true,
    isErrorXmppListRooms: false,
    errMsgXmppListRooms: ' ',
    dataXmppListRooms: initialData
  });
  const dvc = useContext(SelectedDeviceContext);

  useLayoutEffect(() => {
    let didCancel = false;

    (async () => {
      dispatch({ type: 'FETCH_INIT' });
      
      try {
        if(xmppParam.param === '') return;    

        if(xmppParam.param === undefined)
          throw new Error(i18strings.error_checkcommunication);

        let roomList : string[] = [];
        let numRooms = parseInt(xmppParam.param);
        let i = 0;

        while (numRooms > 0) {
          deleteAllRecordFromBuffers();
          const result = await sendXMPPmessage(
            dvc,
            randomID(),
            xmppParam.typefce,
            xmppParam.fce,
            prepareID(i) + (numRooms >= 8 ? "8" : prepareID(numRooms))
          );
          if (result === null) throw new Error(i18strings.error_checkcommunication);
          roomList = roomList.concat(getArrOfNames(result.result, roomLength));
          i += 8;
          numRooms -= 8;
        }
        if(roomList === null) throw new Error(i18strings.error_checkcommunication);
        if (!didCancel) dispatch({ type: 'FETCH_SUCCESS', payload: roomList });
      } catch (e) {
      if (!didCancel) {
        dispatch({ type: 'FETCH_FAILURE', payload: (e as any).message });
      }
    }
  })();

  return () => {
    didCancel = true;
  };

  }, [xmppParam, roomLength]);
  
  return [state, setXmppParam] as const;
};
