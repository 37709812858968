//@ts-check

import React, { useState } from "react";
import { RefresherEventDetail } from '@ionic/core';

import {  IonLabel, IonToast, IonButton, IonList, IonItem, IonSelectOption, IonSelect, IonText } from '@ionic/react';

/* Theme variables */
import '../../theme/variables.css';


/* Icons */
import { thermometer } from "ionicons/icons";

/* XMPP fetch */
import { useDataXMPP } from '../../functions/fetchXMPP';
import { getArrOfNames, prepareID } from "../../functions/fce-string";
import { TIMEOUT_TOAST_SAVED } from "../../App";
import { i18strings } from "../../functions/i18";
import { MyIonRefresher, Help, Page, Wrapper } from "../../functions/myReactComponents";
import { useHandleXmpp } from "../../functions/handleXmpp";

const PageHeatingWTR = (props : any) => {
  const [showLoading, setShowLoading] = useState(true);
  const [modeList] = useDataXMPP({typefce: 'get', fce: '/listOfModes', param: ''}, '');

  const wtrFetch = {typefce: 'get', fce: '/wtr01Settings', param: ''}
  const [wtrSettings, doFetchWtr] = useDataXMPP(wtrFetch, '');

  const [showHelp, setShowHelp] = useState(false);
  const [showToastSaved, setShowToastSaved] = useState(false);
  const [idRegime, setIdRegime] = useState(-1);
  const [temperature, setTemperature] = useState(0);
  const [listRegimes, setListRegimes] = useState<{ id : number, name : string }[]>([]);
  const [unsaved, setUnsaved] = useState(false);

  useHandleXmpp(modeList.isLoading, modeList.data, (resultXmpp) => {
    let result : typeof listRegimes = [];

    try {
      if(resultXmpp.length >= 13) {
        let dataList = getArrOfNames(resultXmpp, 13);
        dataList.unshift('Žádný  ');

        result = dataList.map((row, i) => {
          return { id: i - 1, name: row }
        });
      }
    }
    catch {
      result = [];
    }
    finally {
      setListRegimes(result);
    }
  });

  useHandleXmpp(wtrSettings.isLoading, wtrSettings.data, (resultXmpp) => {
    try {
      let id = parseInt(resultXmpp.substr(1, 2));

      let temperature = parseFloat(resultXmpp.substr(3, 5));
      setTemperature(isNaN(temperature) ? 111: temperature);

      setShowLoading(false);
      setIdRegime(id);
    }
    catch(e) {
      console.error(e);
    }
    finally {
      setTimeout(() => {setUnsaved(false)}, 0);
    }
  });

  const doXmppRefresh = (e : CustomEvent<RefresherEventDetail>) => {
    doFetchWtr(wtrFetch);
    e.detail.complete();
  }

  const RenderItemsOption = () => {
    return (
      <>
        {listRegimes.map((item, index) => (
          <IonSelectOption value={item.id} key={index}>{item.name}</IonSelectOption>
        ))}
      </>
    );
  }

  const handleOnSelectItem = (e, id : number) => {
    setIdRegime(e.detail.value);
    setUnsaved(true);
  }


  const handleOnClickOK = () => {
    var p = prepareID(idRegime);

    const saveXmppObject = {
      typefce: 'SET',
      fce: '/savewtr01Settings',
      param: p
    }
    doFetchWtr(saveXmppObject);

    setShowToastSaved(true);
    setUnsaved(false);
  }

  return (
    <Wrapper loading={showLoading} help={() => setShowHelp(true)}>
      <MyIonRefresher onIonRefresh={doXmppRefresh} />

      <Help isOpen={showHelp} onDidDismiss={e => setShowHelp(false)}>
        <p>{i18strings.help_heating_wtr}</p>
      </Help>

      <IonToast
        isOpen={showToastSaved}
        onDidDismiss={() => setShowToastSaved(false)}
        message={i18strings.action_settings_saved}
        duration={TIMEOUT_TOAST_SAVED}
      />

      <Page title={i18strings.menu_heating_outdoortemp} icon={thermometer} unsaved={unsaved}>
        <IonList>
        <IonItem>
          <IonLabel>{i18strings.menu_heating_wtr_externaltemperature}:</IonLabel>
          <IonText>{temperature===111 ? i18strings.menu_heating_wtr_undetected : temperature + ' °C'}</IonText>
        </IonItem>
        <IonItem>
          <IonLabel>{i18strings.menu_heating_regimeset}:</IonLabel>
          <IonSelect value={idRegime} interface="popover" onIonChange={(e)=>handleOnSelectItem(e, 0)} >
            <RenderItemsOption />
          </IonSelect>
        </IonItem>
        </IonList>
        <IonButton disabled={wtrSettings.err !== null || wtrSettings.isLoading || !unsaved} expand="block" onClick={handleOnClickOK}>{i18strings.action_save}</IonButton>
      </Page>

      {modeList.err}
      {wtrSettings.err}
    </Wrapper>
  )
}
export default PageHeatingWTR;
