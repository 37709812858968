import React, { FC } from "react"
import { Wrapper, Page, Message } from "../../functions/myReactComponents";
import { i18strings } from "../../functions/i18";
import { mail } from "ionicons/icons";
import { IonList, IonButtons, IonButton } from "@ionic/react";
import { msgs } from "../../App";

const PageMessages : FC<{
  hidden : number[],
  setHidden : React.Dispatch<React.SetStateAction<number[]>>
}> = (props) => {
  function HideMessage(id : number) {
    props.setHidden(old => {
      if (old.includes(id)) return old;
      return [...old, id].sort();
    });
  }

  return (
    <Wrapper>
      <Page title={i18strings.title_messages} icon={mail}>
        <IonButtons>
          <IonButton onClick={() => props.setHidden([])}>{i18strings.button_show_all}</IonButton>
          <IonButton onClick={() => props.setHidden(msgs.map(m => m.id))}>{i18strings.button_dismiss_all}</IonButton>
        </IonButtons>
        <IonList>
          {msgs.map((value) => !props.hidden.includes(value.id) &&
            <Message
              date={value.datetime}
              key={value.id}
              dismis={() => {HideMessage(value.id)}}>
                {value.comment}
            </Message>
          )}
        </IonList>
      </Page>
    </Wrapper>
  )
}
export default PageMessages;