//@ts-check

import React, { FC, useState } from "react";
import { RefresherEventDetail } from '@ionic/core';

import { IonLabel, IonIcon, IonList, IonItem } from '@ionic/react';

/* Theme variables */
import '../../theme/variables.css';

/* Icons */
import { informationCircle, sunny, snow, close } from "ionicons/icons";

import { useDataXMPP } from '../../functions/fetchXMPP';
import { i18strings } from "../../functions/i18";
import { MyIonRefresher, Help, Page, Wrapper } from "../../functions/myReactComponents";
import { useHandleXmpp } from "../../functions/handleXmpp";
import { getArrOfNames } from "../../functions/fce-string";

const PageHeatingKCNET = (props : any) => {
  const [showLoading, setShowLoading] = useState(true);

  const defaultXmppObject = {
    typefce: 'get',
    fce: '/getKCNET',
    param: ''
  }
  const [kcnet, doFetchXmpp] = useDataXMPP(
    defaultXmppObject,
    '',
  );

  const [showHelp, setShowHelp] = useState(false);
  const [statusKCNET, setStatusKCNET] = useState<{ id : number, status : number, belongs : number, data : number }[]>([]);
  const [statusGlobalKCNET, setStatusGlobalKCNET] = useState<{ status1 : number, status2 : number }[]>([]);

  useHandleXmpp(kcnet.isLoading, kcnet.data, (resultXmpp) => {
    let result : typeof statusKCNET = [];
    let resultGlobal : typeof statusGlobalKCNET = [];
    try {
      if(resultXmpp.length === 42) {
        //vrací se 8x5 znaků, plus dva znaky asi pro globální KCNET
        //000000000000000000000000000000000000000001
        var _r = getArrOfNames(resultXmpp, 5);
        _r.forEach((val, i) => {
          const v = [parseInt(val[0]), parseInt(val[1])];
          if (i >= 8) {
            resultGlobal.push(
              {
                status1: v[0],
                status2: v[1]
              }
            );
            return;
          }
          result.push({
            id: i,
            status: v[0],
            belongs: v[1],
            data: parseInt(val.slice(2, 5))
          });
        });
      }
    }
    catch {}
    finally {
      setShowLoading(false);
      setStatusKCNET(result);
      setStatusGlobalKCNET(resultGlobal);
    }
  });

  const doXmppRefresh = (e : CustomEvent<RefresherEventDetail>) => {
    doFetchXmpp(
      defaultXmppObject,
    );
    e.detail.complete();
  }

  const IonItemKCNET : FC<{
    item : {
      id : number,
      status : number,
      belongs : number,
      data : number,
    }
  }> = (props) => {
    let labelText = props.item.status === 0 ?
      (props.item.data === 0 ?
        i18strings.menu_heating_kcnet_unused :
        i18strings.menu_heating_kcnet_error) :
      (props.item.status === 1 ?
        i18strings.menu_heating_kcnet_off :
        i18strings.menu_heating_kcnet_on);

    return (
      <IonItem>
        <IonLabel>{i18strings.menu_heating_kcnet_id}: {props.item.id + 1}</IonLabel>
        <IonIcon icon={
            props.item.belongs === 0 ? sunny
          : props.item.belongs === 1 ? snow
          : close
          } size='small' />
        <IonLabel>&nbsp;{labelText}</IonLabel>
      </IonItem>
    )
  }

  const IonItemGlobalKCNET : FC<{
    item : {
      status1 : number,
      status2 : number,
    }
  }> = (props) => {
    let labelText = props.item.status1 ?
      (props.item.status2 === 1 ?
        i18strings.menu_heating_kcnet_off :
        i18strings.menu_heating_kcnet_on) :
      i18strings.menu_heating_kcnet_unused;

    return (
      <IonItem>
        <IonLabel>Global KCNET</IonLabel>
        <IonIcon icon={sunny} size='small'></IonIcon>
        <IonLabel>&nbsp;{labelText}</IonLabel>
      </IonItem>
    )
  }

  return (
    <Wrapper loading={showLoading} help={() => setShowHelp(true)}>
      <MyIonRefresher onIonRefresh={doXmppRefresh}></MyIonRefresher>

      <Help isOpen={showHelp} onDidDismiss={e => setShowHelp(false)}>
        <p>{i18strings.help_heating_kcnet}</p>
        <p>{i18strings.help_heating_kcnet1}</p>
        <p>{i18strings.help_heating_kcnet2}</p>
        <ol>
          <li>{i18strings.help_heating_kcnet3}</li>
          <li>{i18strings.help_heating_kcnet4}</li>
          <li>{i18strings.help_heating_kcnet5}</li>
          <li>{i18strings.help_heating_kcnet6}</li>
        </ol>
        <p>{i18strings.help_heating_kcnet7} <IonIcon icon={sunny}/></p>
        <p>{i18strings.help_heating_kcnet8} <IonIcon icon={snow}/></p>
      </Help>

      <Page title={i18strings.menu_heating_kcnet} icon={informationCircle}>
        <IonList>
          {statusKCNET && statusKCNET.map((item) => (
            <IonItemKCNET key={item.id} item={item}/>              
          ))}
        </IonList>
        <IonList>
          {statusGlobalKCNET && statusGlobalKCNET.map((item, index) => (
            <IonItemGlobalKCNET key={index+8} item={item}/>              
          ))}
        </IonList>
      </Page>

      {kcnet.err}
    </Wrapper>
  )
}
export default PageHeatingKCNET;  
