//@ts-check

import React, { Fragment, useContext, useState } from "react";
import { getArrOfNames } from '../../functions/fce-string';

import { IonLabel, IonCard, IonToast, IonButton, IonItem, IonAlert, IonIcon } from '@ionic/react';

/* Theme variables */
import '../../theme/variables.css';


/* Icons */
import { optionsOutline, swapHorizontal } from "ionicons/icons";
import { RouteComponentProps } from "react-router";
import { useDataXMPP } from '../../functions/fetchXMPP';
import { prepareID } from "../../functions/fce-string";
import { TIMEOUT_TOAST_SAVED, TIMEOUT_TOAST_ERROR, sendXMPPmessage } from "../../App";
import { i18strings } from "../../functions/i18";
import { Help, Page, Wrapper, RegimesAssignment } from "../../functions/myReactComponents";
import { useHandleXmpp } from "../../functions/handleXmpp";
import { SelectedDeviceContext } from "../../context/GlobalContext";
import { randomID } from "../../functions/fce-math";

interface UserDetailPageProps extends RouteComponentProps<{
  id: string;
  name: string;
}> {};

const defaultRegimes = () => [...Array(7)].map((v, i) => {
  return { value: 99, id: i };
});

const PageRollerRoomSet : React.FunctionComponent <UserDetailPageProps> = ({match}) => {
  const [showLoading, setShowLoading] = useState(true);
  const [showHelp, setShowHelp] = useState(false);
  const [showToastSaved, setShowToastSaved] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertRegime, setShowAlertRegime] = useState(false);
  const [isAlertRegime, setIsAlertRegime] = useState(false);
  const [isError, setIsError] = useState(false);
  const [unsaved, setUnsaved] = useState(false);

  const [progList] = useDataXMPP({typefce: 'get', fce: '/listOfPrograms', param: ''}, '');
  const [settings] = useDataXMPP({typefce: 'get', fce: '/rollerShutterSettings', param: prepareID(parseInt(match.params.id))}, '');

  const [listRegimes, setListRegimes] = useState<{ id : number, name : string }[]>([]);
  const [regimesOfRoom, setRegimesOfRoom] = useState(defaultRegimes());
  const dvc = useContext(SelectedDeviceContext);

  useHandleXmpp(progList.isLoading, progList.data, (resultXmpp) => {
    let result : typeof listRegimes = [];

    try {
      result = getArrOfNames(resultXmpp, 13).map((row, i) => {
        return { id: i, name: row};
      });
    }
    catch(e) {
      console.error(e);
      result = [];
    }
    finally {
      setListRegimes(result);
    }
  });

  useHandleXmpp(settings.isLoading, settings.data, (result) => {
    if(result.length >= 2 ) {
      var _r = getArrOfNames(result.slice(0), 2); //ID režimů za sebou

      const newArr = [...regimesOfRoom];
      newArr.forEach((v, i) => {
        let a = 0;
        try {
          a = parseInt(_r[i]);
          if (isNaN(a)) a = 99;
        } catch(e) {
          console.error(e);
          a = 99;
        } finally {
          v.value = a;
        }
      });

      setRegimesOfRoom(newArr);
    }
    else {
      //Pokud je prázdná odpověď, nejsou přiřazeny žádné režimy roletám
      setRegimesOfRoom(defaultRegimes());
    }
    setShowLoading(false);
    setTimeout(()=>setUnsaved(false), 0);
  });

  const handleOnSelectItem = (e, index) => {
    const copy = [...regimesOfRoom];
    copy[index].value = parseInt(e.detail.value);
    setRegimesOfRoom(copy);
    setUnsaved(true);
  }

  const handleOnClickOK = async () => {
    //• ID místnosti (2 znaky)
    //• id režimů jednotlivých dnů (každý režim 2 znaky). Posílá se buď jedno ID nebo sedm ID (celý týden)!

    let isErr = false;
    let reg = '';
    for(let i = 0; i < regimesOfRoom.length; i++) {
      let regime = regimesOfRoom[i].value;

      if (regime === 99) {
        if(i > 1) {
          // incomplete week
          isErr = true;
          setIsAlertRegime(true);
          setShowAlertRegime(true);
        }
        break;
      }
      reg += prepareID(regime);
    };

    if(isErr || isAlertRegime) return;

    setUnsaved(false);
    await sendXMPPmessage(
      dvc,
      randomID(),
      'SET',
      '/saveRollerShutterSettings',
      prepareID(parseInt(match.params.id)) + reg
    );

    setShowToastSaved(true);
  }

  return (
    <Wrapper loading={showLoading} help={() => setShowHelp(true)}>
      <Help isOpen={showHelp} onDidDismiss={e => setShowHelp(false)}>
        <p>{i18strings.help_roller_room}</p>
        <p>{i18strings.help_regime_assignment.split("{ic}").map((v, i, a) => <Fragment key={i}>
          {v}
          {i < a.length - 1 && <IonIcon icon={optionsOutline} style={{fontSize: "1.5em", marginBottom: "-5px"}} />}
        </Fragment>)}</p>
      </Help>

      <IonToast
        isOpen={showToastSaved}
        onDidDismiss={() => setShowToastSaved(false)}
        message={i18strings.action_settings_saved}
        duration={TIMEOUT_TOAST_SAVED}
      />

      <IonAlert
        isOpen = {showAlert}
        onDidDismiss = {() => setShowAlert(false)}
        header = {i18strings.confirm}
        message = {i18strings.confirm_delete_settings}
        buttons = {[
          {
            text: i18strings.button_no,
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {}
          },
          {
            text: i18strings.button_yes,
            handler: () => {setRegimesOfRoom(defaultRegimes()); setUnsaved(true)}
          }
        ]}
      />
      <IonAlert
        isOpen = {showAlertRegime}
        onDidDismiss = {() => setShowAlertRegime(false)}
        header = {i18strings.confirm}
        message = {i18strings.menu_roller_regimewarning1}
        buttons = {[{text: 'OK', handler: () => setIsAlertRegime(false)}]}
      />

      <Page title={i18strings.menu_roller_circuitsetting} icon={swapHorizontal} unsaved={unsaved}>
        <IonItem color='primary'>
          {match.params.name}
        </IonItem>
        <IonButton expand="block" disabled={settings.isLoading || !unsaved} onClick={handleOnClickOK}>{i18strings.action_save}</IonButton>

        <IonCard disabled={settings.isLoading}>
          <RegimesAssignment
            regimeAssignments={regimesOfRoom}
            defaultRegime={99}
            loc="/roller/regimelist"
            handleSelect={handleOnSelectItem}
            possibleRegimes={listRegimes}
          />
        </IonCard>

        <IonButton expand="block" disabled={settings.isLoading || !unsaved} onClick={handleOnClickOK}>{i18strings.action_save}</IonButton>
        <IonLabel>&nbsp;</IonLabel>
        <IonButton expand="block" disabled={settings.isLoading} onClick={()=>setShowAlert(true)}>{i18strings.button_delete_settings}</IonButton>
      </Page>

      {progList.err}
      {settings.err}
      <IonToast
        isOpen={isError}
        onDidDismiss={() => setIsError(false)}
        message = {i18strings.menu_roller_regimewarning2}
        position = "bottom"
        duration = {TIMEOUT_TOAST_ERROR}
        buttons = {[{text: 'OK', role: 'cancel'}]}
      />
    </Wrapper>
  )
}
export default PageRollerRoomSet;
