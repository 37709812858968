//@ts-check

import React, { useState, useEffect } from "react";
import { RefresherEventDetail } from '@ionic/core';

import { IonIcon, IonText,
         IonToast, IonButton, IonList, IonItem,
         IonRange, IonLabel
} from '@ionic/react';

/* Theme variables */
import '../../theme/variables.css';

/* Icons */
import { thermometer } from "ionicons/icons";

import { useDataXMPP } from '../../functions/fetchXMPP';
import { RouteComponentProps } from "react-router";
import { prepareID } from "../../functions/fce-string";
import { TIMEOUT_TOAST_SAVED } from "../../App";
import { i18strings } from "../../functions/i18";
import { MyIonRefresher, Help, Page, Wrapper } from "../../functions/myReactComponents";
import { useHandleXmpp } from "../../functions/handleXmpp";

interface UserDetailPageProps extends RouteComponentProps<{
  id: string;
  name: string;
}> {}

const PageHeatingRoomMaxSet : React.FunctionComponent <UserDetailPageProps> = ({match}) => {
  const [showLoading, setShowLoading] = useState(true);
  const [unsaved, setUnsaved] = useState(0);

  const defaultXmppObject = {
    typefce: 'get',
    fce: '/wholeRoom',
    param: prepareID(parseInt(match.params.id))
  }
  const [room, doFetchXmpp] = useDataXMPP(defaultXmppObject, '');

  const [showHelp, setShowHelp] = useState(false);
  const [showToastSaved, setShowToastSaved] = useState(false);
  const [maxOfset, setMaxOfset] = useState(0);

  useHandleXmpp(room.isLoading, room.data, (resultXmpp) => {
    let result = 0;

    try {
      if(resultXmpp.length >= 45) {
        result = parseFloat(resultXmpp.substr(32,4));
        result = isNaN(result) ? 0 : result;
        setShowLoading(false);
      }
    }
    catch {
      result = 0;
    }
    finally {
      setMaxOfset(result);
      setUnsaved(0);
    }
  });

  useEffect(()=>{setTimeout(()=>setUnsaved(u => u | 2), 0)}, [maxOfset]);

  const doXmppRefresh = (e : CustomEvent<RefresherEventDetail>) => {
    doFetchXmpp(defaultXmppObject);
    e.detail.complete();
  }

  const handleOnChange = (e) => {
    var v = e.detail.value;
    setMaxOfset(v);
    setUnsaved(u => u | (u >> 1));
  }

  const handleOnClickOK = () => {
    doFetchXmpp({typefce: 'SET', fce: '/saveMaxOffset',
      param: prepareID(parseInt(match.params.id)) + prepareID(maxOfset)});

    setShowToastSaved(true);
    setUnsaved(2);
  }

  return (
    <Wrapper loading={showLoading} help={() => setShowHelp(true)}>
      <MyIonRefresher onIonRefresh={doXmppRefresh}></MyIonRefresher>

      <Help isOpen={showHelp} onDidDismiss={e => setShowHelp(false)}>
        <p>{i18strings.help_heating_roommaxset}</p>
      </Help>

      <IonToast
        isOpen={showToastSaved}
        onDidDismiss={() => setShowToastSaved(false)}
        message={i18strings.action_settings_saved}
        duration={TIMEOUT_TOAST_SAVED}
      />

      <Page title={i18strings.temperature_maxoffset} icon={thermometer} unsaved={!!(unsaved & 1)}>
        <IonList>
          <IonItem color='primary'>{match.params.name}</IonItem>
          <IonItem lines="none">
            <IonLabel>{i18strings.temperature_max_change}:</IonLabel><IonText>{maxOfset}°C</IonText>
          </IonItem>
          <IonItem>
            <IonIcon size="small" slot="start" icon={thermometer}/>
            <IonRange max={12} value={maxOfset} onIonChange={handleOnChange} />
            <IonIcon slot="end" icon={thermometer}/>
          </IonItem>
        </IonList>
        <IonButton disabled={room.err !== null || room.isLoading || !(unsaved & 1)} expand="block" onClick={handleOnClickOK}>
          {i18strings.action_save}
        </IonButton>
      </Page>

      {room.err}
    </Wrapper>
  )
}
export default PageHeatingRoomMaxSet;
